import React, { forwardRef } from 'react';
import PaygreenGlobal from './PaygreenGlobal';
import { useDispatch, useSelector } from 'react-redux';
import { USER_CONECS_REMOVE_REQUEST } from '../../constants/userConstants';
import { WsError } from '../../constants/wsError';
import { failRemoveConecs, successRemoveConecs } from '../../actions/userConecsActions';
import useAxiosInterceptors from '../../axios/useAxios';

const PaygreenConecs = ({ formComplete, instrumentComplete, onCheckboxChange, order, paymentMethod, stopPaymentLoading, setPaymentErrorMessage }, ref) => {
  const axiosInstance = useAxiosInterceptors();
  const conecs = useSelector((state) => state.user.conecs)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);

  const handleRemoveConecs = () => {
    if (!user._id) {
      removeAndReload();
      return;
    }
    dispatch({ type: USER_CONECS_REMOVE_REQUEST })
    axiosInstance.put(`/paygreen/instrument/${conecs.instrument}/remove`, { userId: user._id })
      .then(removeAndReload)
      .catch((error) => {
        console.error(WsError.PAYGREEN_REMOVE_ERRROR, error);
        dispatch(failRemoveConecs());
      })
  }

  const removeAndReload = () => {
    dispatch(successRemoveConecs());
    alert('Rafraichissement de la page pour mettre à jour les paiements');
    window.location.reload();
    // window.paygreenjs.unmount(true);
    // initPaygreen();
    // onInitPaygreenEventListeners();
  };

  const conecsLogos = (
    <>
      <img
        className="h-16 rounded-[5px]"
        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/bimpli.png"
      />
      <img
        className="h-16"
        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/sodexo.png"
      />
      <img
        className="h-16"
        src="https://storage.googleapis.com/pikkopay_images/webapp/payment/up.png"
      />
    </>
  );

  return (
    <PaygreenGlobal
      label="Titre restaurant"
      formComplete={formComplete}
      instrumentComplete={instrumentComplete}
      onCheckboxChange={onCheckboxChange}
      order={order}
      stopPaymentLoading={stopPaymentLoading}
      paymentMethod="conecs"
      paygreenPaymentMethod="conecs"
      logos={conecsLogos}
      usedPaymentMethod={paymentMethod}
      setPaymentErrorMessage={setPaymentErrorMessage}
      showForm={!conecs?.buyerId}
      handleRemovePaymentMethod={handleRemoveConecs}
      isConecs
      ref={ref}
    />
  );
};
export default forwardRef(PaygreenConecs);

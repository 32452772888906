import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { BarcodePicker as ScanditSDKBarcodePicker } from "scandit-sdk";
import { setScanSettings } from "../actions/scanActions";
import { loadScanner } from "../utils/ScannerUtils";


let scannerInstance = null;

const Scanner = (props) => {
  // ref
  let reference = useRef(null);
  const scannerInstanceRef = useRef(null);

  // state
  const [scanSetting, setScanSetting] = useState(props.scanSettings);
  const [visible, setVisible] = useState(props.visible);
  const [isLoading, setIsLoading] = useState(true);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.isScannerConfigured) {
      (async () => {
        console.log("---> load scanner in scanner component");
        await loadScanner();
        createScanner();
      })();
    }
  }, []);

  useEffect(() => {
    if (!scannerInstance) {
       createScanner(); // this will create new scanner instacne
    }
    return () => {
      if (scannerInstance) {
        scannerInstance.pauseScanning();
        scannerInstance.pauseCameraAccess();
      }
    };
  }, [reference]);

  // useEffect(() => {
  //   return () => {
  //     if (scannerInstance && props.shouldDestroyInstance) {
  //       console.log("destroying scanner");
  //       scannerInstance = null;
  //       scannerInstance?.destroy();
  //     }
  //   };
  // }, [props.shouldDestroyInstance]);

  useEffect(() => {
    console.log("---> props pause value:", props.pause);
    console.log("---> scanner instance:", scannerInstance);

    if (scannerInstance) {
      if (props.pause === true) {
        scannerInstance.pauseScanning();
        // scannerInstance.pauseCameraAccess();
      } else {
        scannerInstance.resumeScanning();
        if (props.resumeCamera) {
          // scan check
          scannerInstance.accessCamera(true);
        }

        if (reference.current) {

         if (props.reassignHTMLElement) {
            scannerInstance.reassignOriginElement(reference.current);

            if (props.shouldResetAssignScanner) {
              dispatch(setScanSettings(false));
            }
          }


          scannerInstance.on("ready", () => {
            setIsLoading(false);
          });

          if (props.onScan != null) {
            scannerInstance.on("scan", props.onScan);
          }
        }
      }
    }
  }, [props.pause, reference, props.resumeCamera, props.reassignHTMLElement]);


  useEffect(() => {
   if (scannerInstance && props.scanSettings) {
      console.log("---> scan settings executed", scannerInstance);
      scannerInstance.applyScanSettings(props.scanSettings);
      setScanSetting(props.scanSettings);
    }

    if (scannerInstance && visible !== props.visible) {
      scannerInstance.setVisible(props.visible);
      setVisible(props.visible);
    }
  }, [scanSetting, visible, props.scanSettings, props.visible]);


  const createScanner = async () => {
    ScanditSDKBarcodePicker.create(reference.current, props)
      .then((barcodePicker) => {
        console.log("created barcode instance ---> ", props);

        scannerInstance = barcodePicker;

        barcodePicker.setZoom(0);
        barcodePicker.setPinchToZoomEnabled(true);

        // saving scanner in ref
        // scanner.current = barcodePicker;

        // console.log(barcodePicker);
        scannerInstanceRef.current = barcodePicker;
        barcodePicker.on("ready", () => {
          console.log("ready event fired");
          setIsLoading(false);
        });
        if (props.onScan != null) {
          barcodePicker.on("scan", props.onScan);
        }
        /*if (props.onError != null) {
          barcodePicker.on('scanError', props.onError);
        }*/
        if (props.pause === true) {
          barcodePicker.pauseScanning();
          barcodePicker.pauseCameraAccess();
        } else {
          barcodePicker.accessCamera(true);
          barcodePicker.resumeScanning();
        }
      })
      .catch((error) => {
        console.error("caught error: ", error);
        if (error.name === "NotAllowedError") {
          // catch an error from create() method
          // and execute needed functions here
          alert("Camera access denied by user!");
        }
      });
  };

  return (
    <>
      {isLoading && (
        <>
          <div
            className="absolute z-50 w-screen h-screen "
            // style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="z-50 absolute left-2/4 top-[35%] bg-bLue  -translate-x-2/4 ">
              <div class="lds-spinner white">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
      <div ref={reference} />
    </>
  );
};

export default Scanner;

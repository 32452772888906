import React from 'react';
import closeArrow from '../../styles/img/account/closeArrow.svg';
import camOff from '../../styles/img/account/camOff.svg';

export const ReloadPage = ({ titre,message, onClick,position }) => {
    const [showToatser, setShowToaster] = React.useState(true)
  return (
    <div className={`ReloadPage ${position} ${showToatser ? 'show' : 'hide'}`}>
        <button onClick={()=>setShowToaster(false)} >
        <img src={camOff} alt="cam" className='cam' />
        <img src={closeArrow} alt="close" className='close' />
        </button>
        <div className="content">
            <span>{titre}</span>
            {/* <p>
                {message}
            </p> */}
            <button onClick={onClick}>{message}</button>
        </div>
   
    </div>
  );
}



import { signInWithEmailAndPassword } from 'firebase/auth';
import Swal from 'sweetalert2';

const HandleConnectEmail = async(auth, email, password, user) => {
    try{
        const result = await signInWithEmailAndPassword(auth, email, password)
        var newResult = { email: result.user.email };
        if(user.creditCard && user.creditCard[0] && Object.keys(user.creditCard[0]).length > 0){
            const newCreditCard = {
            id: user.creditCard[0].id,
            brand: user.creditCard[0].brand,
            last4: user.creditCard[0].last4,
            pm: user.creditCard[0].pm,
            img: user.creditCard[0].img
            }
            newResult.creditCard = newCreditCard
        }
        if(user.payzenCreditCards && user.payzenCreditCards[0] && Object.keys(user.payzenCreditCards[0]).length > 0){
            const newPayzenCreditCard = {
                brand: user.payzenCreditCards[0].brand,
                last4: user.payzenCreditCards[0].last4,
                pm: user.payzenCreditCards[0].pm,
                img: user.payzenCreditCards[0].img
            }
            newResult.payzenCreditCards = newPayzenCreditCard
        }
        if(user.currentPromo && Object.keys(user.currentPromo).length > 0){
            newResult.currentPromo = user.currentPromo
        }
        newResult.currentPromo = user.currentPromo || []
        if(user.appliedPromo && Object.keys(user.appliedPromo).length > 0){
            newResult.appliedPromo = user.appliedPromo
        }
        if(user.conecs && Object.keys(user.conecs).length > 0){
            newResult.conecs = user.conecs
        }
        if(user.usedPromo && Object.keys(user.usedPromo).length > 0){
            console.log(user.usedPromo)
            console.log(Object.keys(user.usedPromo).length)
            newResult.usedPromo = user.usedPromo

        }
        if(user.edenred && Object.keys(user.edenred).length > 0){
            newResult.edenred = user.edenred
        }
        return {result, newResult}
    } catch(e) {
        Swal.fire({
            title: 'Email ou mot de passe incorrect',
            icon: 'error',
            closeButtonHtml: 'X',
            confirmButtonColor: '#FFD700',
            confirmButtonText: 'Ok',
            buttonsStyling: false,
            customClass: {
                title: 'title-class',
                confirmButton: 'bg-yellow-400 payer w-[100px]   text-center h-16 geomanistBold text-[1.7rem] rounded-md',
            }

        })
        return {result: null, newResult: null}
   }
};

export default HandleConnectEmail;

import React, { useEffect, useState } from 'react';
import BottomSlider from '../../../components/slider/BottomSlider';
import CustomLoader from '../../../components/loader/CustomLoader';
import useAxiosInterceptors from '../../../axios/useAxios';
import CompanyTile from '../../../components/tile/CompanyTile';
import { useNavigate } from 'react-router-dom';
import { ADD_FID_CARD } from '../../../constants/routes';

const AddFidCardSlider = ({ onClose, isOpen }) => {
  const axiosInstance = useAxiosInterceptors();
  const navigate = useNavigate()

  const [fidCards, setFidCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // TODO : récupérer les carts de fidélité
    setIsLoading(true);
    axiosInstance.get('/fidelity-card')
      .then((response) => setFidCards(response.data))
      .catch((error) => console.error({ error }))
      .finally(() => setIsLoading(false))
  }, []);

  const onClick = (fidCard) => {
    navigate(ADD_FID_CARD, {state: {fidCard}})
  };

  return (
    <BottomSlider
      onClose={onClose}
      isLoading={isLoading}
      isOpen={isOpen}
      hasMask
    >
      <div className="fid-card-slider">
        <div className="fid-card-slider-title">
          Ajouter une carte
        </div>
        {isLoading
          ? (<CustomLoader />)
          : (
            <div className="fid-card-slider-content">
              {fidCards.map((fidCard) => (
                <CompanyTile
                  onClick={() => onClick(fidCard)}
                  name={fidCard.name}
                  image={fidCard.companyImage}
                  id={fidCard._id}
                />
              ))}
            </div>
          )}
      </div>
    </BottomSlider>
  );
};

export default AddFidCardSlider;

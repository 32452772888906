import { CB_CARD_FAIL, CB_CARD_REQUEST, CB_CARD_SUCCESS, CB_REMOVE_CARD_FAIL, CB_REMOVE_CARD_REQUEST, CB_REMOVE_CARD_SUCCESS } from "../constants/cbConstants";
import { USER_CB_CARD_FAIL, USER_CB_CARD_REQUEST, USER_CB_CARD_SUCCESS, USER_CB_REMOVE_CARD_FAIL, USER_CB_REMOVE_CARD_REQUEST, USER_CB_REMOVE_CARD_SUCCESS } from "../constants/userConstants";
import { getErrorMessage } from '../utils/WsUtils';

function imgCb (brand){
  console.log(brand)
  switch(brand){
    case 'visa':
      return "https://d2csxpduxe849s.cloudfront.net/media/F44207E3-1DDE-4798-B0FCC94F6227FCB7/FD889B2B-B4FE-445C-97A356E3955CC1CC/webimage-ED81074F-347A-430E-AC7CC0A3429D9570.jpg"
    case 'mastercard':
      return "https://logos-marques.com/wp-content/uploads/2021/07/Mastercard-logo.png"
    case 'amex':
      return "https://bitexpert.io/wp-content/uploads/2018/07/AmericanExpress.jpg"
    default:
      return
  }

}

export const getCardInfo = (pm, user, axiosInstance, setPaymentErrorMessage) => async(dispatch, getState) => {
    dispatch({type: USER_CB_CARD_REQUEST})
    try{
        axiosInstance.post('/user/addCard', { pm: pm, user: user })
          .then((response) => {
            dispatch({ type: USER_CB_CARD_SUCCESS, payload: [response.data.customer] });
            localStorage.setItem("user", JSON.stringify(getState().user));
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error);
            setPaymentErrorMessage(errorMessage);
            dispatch({type: USER_CB_CARD_FAIL});
          })
    } catch(error){
      console.log(error);
      dispatch({type: USER_CB_CARD_FAIL})
    }
  }

  export const removeCB = (userId, creditCard, axiosInstance) => async(dispatch, getState) => {
    dispatch({type: USER_CB_REMOVE_CARD_REQUEST})
    try{

      const {data} = await axiosInstance.post(`/user/deleteCard`, {
        userId: userId,
        creditCard: creditCard
      })
      console.log(data)
      dispatch({type: USER_CB_REMOVE_CARD_SUCCESS})

      localStorage.setItem(
        "user",
        JSON.stringify(getState().user)
      );

    }catch(err){
        console.log(err)
        dispatch({type: USER_CB_REMOVE_CARD_FAIL})
        localStorage.setItem(
          "user",
          JSON.stringify(getState().user)
        );
    }


  }

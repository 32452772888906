import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

export const Toast = (type, text) => {
    
    toast.configure({
        position: "bottom-right",
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        autoClose: 1200,
        limit: 1
    })

    
    switch (type) {
    case 'success':
        console.log('hello');
        toast.success(text);
        break;
    case 'error':
        toast.error(text);
        break;
    case 'info':
        toast.info(text)
        break;
    default:
        return;
    }
    
    
        

    return (
        <ToastContainer />
    )
};


export const ToastInternet = (type, text) => {
    console.log(type)
    console.log(text)
    toast.configure({
        position: "top-right",
        theme: "colored",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        autoClose: false,
        closeButton: false,
    })

  

    const display = () => {
        
        switch(type){
            case "internet":
                toast.info(text)
                break
            case "noInternet":
                toast.warning(text)
                break
            default:
                return
            
        }
    } 
    return (
        <>
       {display()}
        </>
    );

    
};



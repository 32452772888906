import React from 'react';
import PageLoader from '../loader/PageLoader';
import closeArrow from '../../styles/img/account/closeArrow.svg';

const BottomSlider = (props) => {
  const { isOpen, isLoading, onClose, hasMask } = props;

  const varstyle = {
    boxShadow: isOpen ? "0 0 5px rgb(0 0 0 / 20%)" : "",
    height: '72%',

  };

  return (
    <div
      className={`transition-all duration-400 fixed w-[100vw] left-0 top-0 z-50 slider`}
      style={{ zIndex: 101 }}
    >
      <div onClick={onClose} className={`fixed top-0 left-0 w-full h-full ${hasMask ? 'slider-mask' : ''}`} />
      <div
        className={`${isOpen ? "slider overfow-auto bg-white z-[50] p-8 w-[100%] transform translate-x-[-50%] left-[50%] gap-8 mx-auto flex flex-col justify-between items-center rounded-tr-[20px] bottom-0  rounded-tl-[20px] fixed  shadow-lg duration-500 slideuppopupXY " : "bg-white z-[50] p-8 w-[100%] shadow transform translate-x-[-50%] translate-y-[100%] left-[50%] bottom-0  gap-8 mx-auto flex flex-col justify-around items-center rounded-tr-[20px]  rounded-tl-[20px] fixed  shadow-lg "}`}
        style={varstyle}
      >
         <div onClick={onClose} className='fixed top-10 right-10'>
            <img src={closeArrow} alt="" />
          </div>
        {isLoading
          ? (<PageLoader />)
          : (<>{ props.children }</>)
        }
      </div>
    </div>
  );
};

export default BottomSlider;

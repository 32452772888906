// export const MINIMUM_PRICE = 0.01;

export const MINIMUM_PRICE = 0.5;
export const isAllowedToPay = (cartItems) => {
  return cartItems && (cartItems.promoPrice >= MINIMUM_PRICE
    || (cartItems.promoPrice === 0 && cartItems.itemsPrice >= MINIMUM_PRICE));
};

export const isAllowToChangeStore = (store) => {
  const exclusiveStores = process.env.REACT_APP_EXCLUSIVE_STORES.split(',');
  return !exclusiveStores.some(element => store.name.toUpperCase().includes(element))
}
export const isPayzenModule = (store) => {
  return store?.paymentGateway === 'payzen'
}
export const isTRavailable = (cartItems) => {
  return !cartItems.some(item => item.TR === false)

}
import React, { useEffect, useState } from 'react';
import { ACCOUNT, USER_FID_CARD, USER_FID_CARD_DETAIL } from '../../../constants/routes';
import ScanFooter from '../ScanFooter';
import BackButton from '../../../components/button/BackButton';
import PageLoader from '../../../components/loader/PageLoader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAxiosInterceptors from '../../../axios/useAxios';
import FidelityCardTile from './FidelityCardTile';
import addButtonIcon from '../../../styles/img/add-button-circle.png';
import AddFidCardSlider from './AddFidCardSlider';

const UserFidelityCard = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxiosInterceptors();
  const user = useSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false);
  const [fidCards, setFidCards] = useState([]);
  const [showAddFidCardSlider, setShowAddFidCardSlider] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance.get(`/user/${user._id}/fidelity-cards`)
      .then((response) => setFidCards(response.data))
      .catch((error) => {
        console.error({ error }) // TODO : handle error
      })
    .finally(() => setIsLoading(false))
  }, []);

  const onClickFidCard = (fidCard) => {
    navigate(USER_FID_CARD_DETAIL, { state: {
      fidCard: {
        fidelityCardPhysicalId: fidCard.externalId,
        fidelityCardBddId: fidCard._id
      }
    }});
  };
  const onClickAddFidCard = () => {
    setShowAddFidCardSlider(true);
  };

  return (
    <div id="user-fidelity-card">
      {!isLoading
        ? (
          <div className="user-fidelity-card-body">
            <div className="user-fidelity-card-content">
              <BackButton onClick={() => navigate(ACCOUNT)} />
              <div className="user-fidelity-card-title">Mes cartes fidélité</div>
              <div className="user-fidelity-card-tile-container">
                {fidCards.length > 0 && (
                  <>{fidCards.map((fidCard) =>
                    <FidelityCardTile onClick={() => onClickFidCard(fidCard)} img={fidCard.companyImage} />)}
                  </>
                )}
                <div className="fidelity-card-tile" onClick={onClickAddFidCard}>
                  <div className="add-card-button">
                    <img className="add-card-img" src={addButtonIcon} />
                    <div>Ajouter une carte</div>
                  </div>
                </div>
              </div>
            </div>
            {showAddFidCardSlider && (
              <AddFidCardSlider
                isOpen={showAddFidCardSlider}
                onClose={() => setShowAddFidCardSlider(false)}
              />
            )}
          </div>
        )
        : (<PageLoader />)
      }
      <ScanFooter originPath={USER_FID_CARD} />
    </div>
  );
};

export default UserFidelityCard;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { findUser } from '../../actions/userActions';
import useAxiosInterceptors from '../../axios/useAxios';
import menuBurger from '../../styles/img/menu-burger.png';
import doubleArrow from '../../styles/img/arrow/double-arrow.png';
import { ACCOUNT, REWARD } from '../../constants/routes';
import SecondaryButton from '../button/SecondaryButton';
import PikkoPointContainer from '../pikko-box/PikkoPointContainer';
import buttonBackBlack from '../../styles/img/button/button_back_black.svg';
import headerBackground from '../../styles/img/background/header-background.png';
import blackGift from '../../styles/img/blackGift.svg';
const PikkoPointHeader = ({ user, isMainPage, onClick, onBack,notEuroVersion }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInterceptors()

    const handleSettings = () => {
        console.log('handle settings');
    };

    const convertPikkoPoints = () => {
        navigate(REWARD);
    };

    const onBackClick = () => {
        if (onBack) {
            onBack();
        } else {
            navigate(ACCOUNT);
        }
    };

    return (
      <div className="pikko-point-header">
        <img className="header-background-img" src={headerBackground} />
        <div className="header-content">
          <div className="action-button">
            {isMainPage ? (
                <div onClick={onClick}>
                  <img src={menuBurger} alt="chevronBottom" />
                </div>
              )
              : (
                <div onClick={onBackClick}>
                  <img src={buttonBackBlack} alt="buttonBackBlack" />
                </div>
              )
            }
            
          </div>
          <div className="point-container">
            <PikkoPointContainer notEuroVersion user={user} />
          </div>
          {isMainPage && (
          <div className='second-btn'>
              <SecondaryButton
              onClick={convertPikkoPoints}
              label="Convertir mes pikko points"
              icon={blackGift}
            />
          </div>
          )}
        </div>
      </div>
    );
};

export default PikkoPointHeader;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//const firebaseConfig = {
//  apiKey: "AIzaSyC_tEjxnPthcrx4tglddPH4r9C7ryN8ZSs",
//  authDomain: "www.jilswebapp1.pikkopay.fr",
//  projectId: "pikkopay",
//  storageBucket: "pikkopay.appspot.com",
//  messagingSenderId: "776653098817",
//  appId: "1:776653098817:web:8e6fcd735cf1020ec39bc0",
//  measurementId: "G-ELX6RNGF2H"

//};


// localhost

// const firebaseConfig = {
//   apiKey: "AIzaSyC_tEjxnPthcrx4tglddPH4r9C7ryN8ZSs",
//   authDomain: "pikkopay.firebaseapp.com",
//   projectId: "pikkopay",
//   storageBucket: "pikkopay.appspot.com",
//   messagingSenderId: "776653098817",
//   appId: "1:776653098817:web:8e6fcd735cf1020ec39bc0",
//   measurementId: "G-ELX6RNGF2H"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyC_tEjxnPthcrx4tglddPH4r9C7ryN8ZSs",
//   authDomain: "www.webapp.pikkopay.fr",
//   projectId: "pikkopay",
//   storageBucket: "pikkopay.appspot.com",
//   messagingSenderId: "776653098817",
//   appId: "1:776653098817:web:7f2bfa281ac9e77dc39bc0",
//   measurementId: "G-DBQNYZH1BB"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)
export default app

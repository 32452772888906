import { WsError } from '../constants/wsError';

export const getErrorMessage = (error) => {
  if (error?.response?.data?.errorCode) {
    if (WsError[error.response.data.errorCode]) {
      return WsError[error.response.data.errorCode];
    }
    return WsError.UNHANDLED_ERROR;
  }
  return WsError.DEFAULT_ERROR;
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADD_FID_SCAN, USER_FID_CARD } from '../../../../constants/routes';
import { confirmWarning, notifyInfo, notifyWarning } from '../../../../utils/Notify';
import Config from '../../../../axios/Config';
import FidCardScreen from './FidCardScreen';
import { addFidCard } from '../../../../actions/userActions';
import { identify } from '../../../../actions/fidelityActions';

const UserAddFidCardScreen = () => {
  const navigate = useNavigate();
  const axiosInstance = Config();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)
  const { cartItems } = useSelector(state => state.cart)
  const [recaptchaResultToken, setRecaptchaResultToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();

  const onVerification = (fidCardNum) => {
    if (!fidCardNum) {
      notifyWarning('Vous devez saisir un numéro de carte de fidélité.');
      return;
    }

    const isCardWillBeReplaced = user?.fidCard && user.fidCard
      .some((card) => state?.fidCard?._id === card.fidelityCardBddId);
    const isCardAlreadyAdded = user?.fidCard && user.fidCard
      .some((card) => fidCardNum === card.fidelityCardPhysicalId)

    if (isCardWillBeReplaced && isCardAlreadyAdded) {
      notifyInfo('Tu as déjà ajouté cette carte à ton compte.');
    } else if (isCardWillBeReplaced) {
      confirmWarning( // title, text, confirmText, callBack
        'Tu as déjà une carte de fidelité',
        'Tu possèdes déjà une carte de fidélité avec cette enseigne. Souhaites-tu la remplacer ?',
        'Oui',
        () => addCard(fidCardNum)
      );
    } else {
      addCard(fidCardNum);
    }
  }

  const onIdentifySuccess = (fidCardNum, data) => {
    if (data.userMaskedEmail) {
      notifyWarning('Cette carte déjà associée à un compte');
    } else {
      dispatch(addFidCard(axiosInstance, user._id, fidCardNum, state.fidCard.companyId, () => navigate(USER_FID_CARD)));
    }
  };

  const addCard = (fidCardNum) => {
    setIsLoading(true);
    identify(
      axiosInstance, user, cartItems, fidCardNum, recaptchaResultToken,
      (data) => onIdentifySuccess(fidCardNum, data),
      () => setIsLoading(false)
    );
  }

  const goToScan = () => {
    navigate(ADD_FID_SCAN, {state: { fidCard : state.fidCard }});
  };

  return (
    <FidCardScreen
      onConfirm={onVerification}
      onBack={() => navigate(USER_FID_CARD)}
      goToScan={goToScan}
      companyLogo={state?.fidCard?.companyImage}
      setRecaptchaResultToken={setRecaptchaResultToken}
      isLoading={isLoading}
    />
  );
};

export default UserAddFidCardScreen;


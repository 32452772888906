import React from 'react';
import * as ScanditSDK from 'scandit-sdk';
import Scanner from '../../../../components/Scanner';
import BackButton from '../../../../components/button/BackButton';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import keyboard from '../../../../styles/img/account/keyboard.svg';

const FidScanScreen = ({ onScan, fidScanLoading, goToCardScreen, companyLogo,companyName, setRecaptchaResultToken }) => {

  const getScanSettings = () => {
    return new ScanditSDK.ScanSettings({
      enabledSymbologies: [
        "ean8",
        "ean13",
        "upca",
        "code128",
        "qr",
      ],
      codeDuplicateFilter: 2500,
      searchArea: { x: 0.025, y: 0.3, width: 0.95, height: 0.3 },
      maxNumberOfCodesPerFrame: 1,
    });
  };

  const addPicture = () => {
    // TODO
  };

  const onScanNotWorking = async () => { // Same function as the one in Scan.jsx component
    // Efface dans le indexDB
    window.indexedDB.deleteDatabase('/scandit_sync_folder_preload');
    window.indexedDB.deleteDatabase('/scandit_sync_folder')
    localStorage.removeItem('scandit-device-id')
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.trim().startsWith('_') ? c : c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    sessionStorage.clear();

    caches.open('my-cache').then(function(cache) {
      cache.keys().then(function(keys) {
        keys.forEach(function(request) {
          cache.delete(request);
        });
      }).then(() => window.location.reload());
    });
  }

  return (
        <div>
            {fidScanLoading ? (
                <>
                  <div
                    className="absolute z-50 h-screen w-screen "
                    style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                  >
                    <div className="z-50 absolute left-2/4 top-[33%]  -translate-x-2/4 ">
                      <div className="lds-spinner white">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            <div className="min-h-full min-w-full ScanFidelity  bg-black text-white overflow-hidden " >
            <div className='fixed top-4 left-4 w-full h-16  flex items-center justify-between px-4 z-50'>
           <BackButton onClick={goToCardScreen} isWhite={true} />
            </div>
                <div className="contenairestoreLogoFid_box">
                    <div className="titre_scanfid">
                        Associer ma carte de fidélité
                    </div>
                    <div className="contenairestoreLogoFid">
                    <img src={companyLogo} alt="logo" className="store_logo" />
                    <span>
                        {companyName}
                    </span>
                </div>
                </div>

                <div className='absolute_btn_fidelityscan'>
                      <button className='indication_fidscan' onClick={onScanNotWorking}>
                        Le scan ne fonctionne pas?
                      </button>
                      <button className="saisie_manuelleFidscan" onClick={goToCardScreen}>
                          Saisie manuelle
                          <img src={keyboard} alt="keyboard" className="keyboard" />
                      </button>
                     
                </div>
                {setRecaptchaResultToken && (
                  <div className="relative top-10">
                    <GoogleReCaptcha onVerify={setRecaptchaResultToken} action="login" />
                  </div>
                )}
                <Scanner
                    preloadBlurryRecognition={true}
                    preloadEngine={true}
                    accessCamera={true}
                    guiStyle={ScanditSDK.BarcodePicker.GuiStyle.VIEWFINDER}
                    viewFinderArea={{ x: 0.025, y: 0.3, width: 0.95, height: 0.3 }}
                    onScan={(scanResult) => {
                      onScan(scanResult.barcodes[0].data);
                    }}
                    onProcessFramze
                    scanSettings={getScanSettings()}
                    videoFit={ScanditSDK.BarcodePicker.ObjectFit.COVER}
                    playSoundOnScan={true}
                    enableCameraSwitcher={false}
                    enablePinchToZoom={false}
                    enableTapToFocus={true}
                    enableTorchToggle={false}
                    mirrorImage={true}
                    targetScanningFPS={10}
                    zoom={0}
                />
            </div>
        </div>
    );
};

export default FidScanScreen;

import React from 'react';
import buttonBackBlack from '../../styles/img/button/button_back_black.svg';
import buttonBackWhite from '../../styles/img/button/button_back_white.svg';
import logo_and_pikko from '../../styles/img/logo_and_pikko.svg';
const BackButton = ({onClick, isAbsolute, isWhite,isLogoVisible}) => {
    return (
      <div id="back-button" style={{width:'fit-content'}}  className={`back-button ${isAbsolute ? 'absolute' : ''}`}>
        <img
           onClick={onClick}
          src={isWhite ? buttonBackWhite : buttonBackBlack}
          className="back-button-img"
        />
        {/*{isLogoVisible && <img */}
        {/*  src={logo_and_pikko}*/}
        {/*  className="back-button-logo"*/}
        {/*/>}*/}

      </div>
    );
};

export default BackButton;

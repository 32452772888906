import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dell from '../../styles/img/dell.svg'
import { WsError } from '../../constants/wsError';
import {
  getConecsUserinfo,
  paygreenCapture,
  paygreenCreateBuyer,
  paygreenCreateOrder, storePaygreenBankCardInfo
} from '../../actions/userConecsActions';
import { getErrorMessage } from '../../utils/WsUtils';
import useAxiosInterceptors from '../../axios/useAxios';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo } from '../../actions/userActions';
import PaygreenConecsForm from './PaygreenConecsForm';
import PaygreenUserForm from './bank-card/PaygreenUserForm';

let paymentEventTriggered = false;

const PaygreenGlobal = ({
                          label,
                          formComplete,
                          instrumentComplete,
                          onCheckboxChange,
                          order,
                          paygreenPaymentMethod,
                          paymentMethod,
                          usedPaymentMethod,
                          stopPaymentLoading,
                          setPaymentErrorMessage,
                          logos,
                          showForm,
                          handleRemovePaymentMethod,
                          isConecs,
                        }, ref) => {
  const axiosInstance = useAxiosInterceptors();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const conecs = useSelector((state) => state.user.conecs)

  const [userEmail, setUserEmail] = useState(user.email ? user.email : '')
  const [cardNbComplete, setCardNbComplete] = useState();
  const [cardExpComplete, setCardExpComplete] = useState();
  const [cardCVCComplete, setCardCVCComplete] = useState();
  const [userName, setUserName] = useState(`${user.nom ?? ''} ${user.prenom ?? ''}`);
  const { cartItems } = useSelector(state => state.cart)
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false);
  const [paymentInputLoading, setPaymentInputLoading] = useState(false);
  const [showPaymentFields, setShowPaymentFields] = useState(true); // TODO : handle
  const [errorMessage, setErrorMessage] = useState('');
  const [paygreenShop, setPaygreenShop] = useState('')
  const [userBankCard, setUserBankCard] = useState(undefined);

  const isChecked = paymentMethod === usedPaymentMethod;
  const isContentVisible = isChecked && showForm;

  const paygreen_endpoint = process.env.REACT_APP_PAYGREEN_API_ENDPOINT;
  const paygreenJs = process.env.REACT_APP_PAYGREEN_JS_ENDPOINT;
  const paygreen = process.env.REACT_APP_PAYGREEN_PUBLIC_KEY;

  const handleSaveCard = async (state) => {
    setSave(!save)
    await window.paygreenjs.updateConsent(!save)
  };

  const handleCheckboxChange = () => {
    onCheckboxChange();
  };

  useImperativeHandle(ref, () => ({
    handleSubmitPaygreen,
  }));

  const styless = {
    input: {
      base: {
        color: 'red',
        fontSize: '10px',
        background: 'white',
      },
      hover: {
        color: 'grey',
      },
      focus: {
        color: 'grey',
      },
      invalid: {
        color: 'red',
      },
      placeholder: {
        base: {
          color: 'grey',
        },
      },
    },
    checkbox: {
      label: {
        base: {
          color: 'black',
        },
        unchecked: {
          color: 'red',
        },
      },
      box: {
        base: {
          color: 'black',
          hover: {
            color: 'red',
          },
        },
        unchecked: {
          color: 'red',
        },
      },
    },
  }

  const initPaygreenPayment = async (paygreenOrder, paygreenUser) => {
    await window.paygreenjs.init({
                                   paymentOrderID: paygreenOrder.id,
                                   objectSecret: paygreenOrder.object_secret,
                                   mode: 'payment',
                                   publicKey: paygreen,
                                   instrument: paygreenUser.instrument,
                                   styless
                                 });

    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.PAYMENT_FAIL,
      (event) => {
        setPaymentErrorMessage(WsError.PAYGREEN_PAYMENT_FAIL);
      },
    );
    handlePaymentDone(paygreenOrder.id, paygreenUser);
  }

  const handleSubmitPaygreen = async (cartItems) => {
    axiosInstance.get(`store/conecsId/${cartItems.storeId}`)
                 .then(async (result) => {
                   setPaymentErrorMessage('');
                   const order_amount = cartItems.promoPrice > 0 ? cartItems.promoPrice : cartItems.itemsPrice;

                   if (order_amount < 1) {
                     setPaymentErrorMessage(WsError.PAYGREEN_MINIMUM_AMOUNT_ERROR)
                     return;
                   }

                   console.log('handleSubmitConecs INSTRUMENT');

                   const { data } = await axiosInstance.put('/paygreen/instrument', {
                     instrumentId: conecs.instrument
                   })
                   const conecs_balance = data.data.daily_balance;

                   if (conecs_balance < order_amount * 100) {
                     setPaymentErrorMessage(WsError.PAYGREEN_INSUFFICIENT_FUNDS_ERROR)
                     stopPaymentLoading();
                   } else {
                     const paygreenUser = conecs;
                     paygreenCreateOrder(paygreenUser, order_amount, result.data.paygreenShop, axiosInstance)
                       .then(async (response) => {
                         const paygreenOrder = response.data.data;
                         try {
                           if (!window.paygreenjs) {
                             const script = document.createElement('script');
                             script.src = paygreen_endpoint;
                             script.async = true;
                             script.onload = async () => {
                               await initPaygreenPayment(paygreenOrder, paygreenUser);
                             }
                             document.body.appendChild(script);
                           } else {
                             await initPaygreenPayment(paygreenOrder, paygreenUser);
                           }
                         } catch (error) {
                           stopPaymentLoading();
                           console.error(WsError.PAYGREEN_INIT_ERRROR, error);
                           setPaymentErrorMessage(WsError.PAYGREEN_INIT_ERRROR);
                         }
                       })
                       .catch((error) => {
                         stopPaymentLoading();
                         console.error(WsError.PAYGREEN_CREATE_ORDER_ERROR, error);
                         const errorMessage = getErrorMessage(error);
                         setPaymentErrorMessage(errorMessage);
                       })
                   }
                 })
  }

  const onInitPaygreenEventListeners = () => {
    window.paygreenjs.attachEventListener(window.paygreenjs.Events.PAN_FIELD_FULFILLED, (event) => {
      // document.getElementById('pan-check')?.classList.remove('invisible')
      setCardNbComplete(true)
    });

    window.paygreenjs.attachEventListener(window.paygreenjs.Events.EXP_FIELD_FULFILLED, (event) => {
      // document.getElementById('exp-check')?.classList.remove('invisible')
      setCardExpComplete(true)

    });

    window.paygreenjs.attachEventListener(window.paygreenjs.Events.CVV_FIELD_ONCHANGE, (event) => {
      if (event.detail.valid) {
        document.getElementById('cvv-check')?.classList.remove('invisible')
        setCardCVCComplete(true)
      } else {
        setCardCVCComplete(false)
      }
    });
  }

  const initPaygreen = () => {
    setShowPaymentFields(true);
    setPaymentInputLoading(true);
    axiosInstance.get(`store/conecsId/${cartItems.storeId}`)
                 .then(async (result) => {
                   setPaygreenShop(result.data.paygreenShop)
                   await paygreenjsInit(result);
                   setPaymentInputLoading(false);
                 })
                 .catch(() => setPaymentInputLoading(false))
  }

  const paygreenjsInit = async (result) => {
    await window.paygreenjs.init({
                                   publicKey: paygreen,
                                   mode: 'instrument',
                                   buyer: !isConecs && userBankCard ? userBankCard.data.id : undefined,
                                   paymentMethod: paygreenPaymentMethod,
                                   modeOptions: {
                                     // authorizedInstrument: true,
                                     shopId: result.data.paygreenShop, // TODO : comment tempo => Utile ?
                                   },
                                   styless
                                 });
  };

  function countWords(str) {
    return str.split(/\s+/).filter(function (word) {
      return word.length > 0;
    }).length;
  }

  function validateEmail(email) {
    // Expression régulière basique pour valider une adresse e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  }

  const createBuyer = (callBack) => {
    setErrorMessage('');
    if (countWords(userName) <= 1 || validateEmail(userEmail) <= 0) {
      setErrorMessage(WsError.PAYGREEN_INFORMATION_MISSING);
      return;
    }

    paygreenCreateBuyer({ name: userName, email: userEmail }, axiosInstance)
      .then((response) => {
        setUserBankCard(response.data);
        callBack();
      })
  }

  const handleSubmit = async () => {

    console.log('handleSubmit');

    setErrorMessage('');
    if (countWords(userName) <= 1 || validateEmail(userEmail) <= 0) {
      setErrorMessage(WsError.PAYGREEN_INFORMATION_MISSING);
      return;
    }
    const order_amount = cartItems.promoPrice > 0 ? cartItems.promoPrice : cartItems.itemsPrice;

    let userConecs;
    const userInfo = {
      name: userName,
      email: userEmail.trim()
    }

    if (order_amount < 1) {
      setErrorMessage(WsError.PAYGREEN_MINIMUM_AMOUNT_ERROR);
      return;
    }

    // if (!isConecs) {
    //   const { data } = await paygreenCreateBuyer({ name: userName, email: userEmail }, axiosInstance);
    //   // / await window.paygreenjs.unmount(true);
    //   // / await window.paygreenjs.unmount();
    //   await paygreenjsInit(undefined, data.data.id);
    //   userBankCard = data;
    // }

    console.log('PAYE window.paygreenjs', window.paygreenjs);

    try {
      if (window.paygreenjs) {
        setLoading(true);

        console.log('window.paygreenjs ---> 1', window.paygreenjs);

        await window.paygreenjs.submitPayment();

        console.log('window.paygreenjs ---> 2', window.paygreenjs);

        window.paygreenjs.attachEventListener(window.paygreenjs.Events.TOKEN_READY, (event) => {
          console.log('TOKEN_READY event', event);
        });

        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.FULL_PAYMENT_DONE,
          (event) => console.log('Payment success'),
        );

        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.INSTRUMENT_READY,
          async (event) => {

            console.log('handleSubmit event INSTRUMENT_READY', event);

            if (event.detail.instrument.daily_balance < order_amount * 100) { // TODO : No balance fr credit card
              handleError(WsError.PAYGREEN_INSUFFICIENT_FUNDS_ERROR);
            } else {
              if (isConecs) {
                paygreenCreateBuyer(userInfo, axiosInstance)
                  .then(async (response) => {

                    console.log('handleSubmit paygreenCreateBuyer response', response);

                    userConecs = {
                      buyerId: response.data.data.id,
                      email: response.data.data.email,
                      firstName: response.data.data.firstName, //prénom
                      lastName: response.data.data.lastName, //nom
                      instrument: event.detail.instrument.id,
                      balance: event.detail.instrument.daily_balance,
                      brand: event.detail.instrument.issuer,
                      reuse: event.detail.instrument.reuse_allowed,
                      last4: event.detail.instrument.last4
                    }
                    await window.paygreenjs.unmount(true);
                    setShowPaymentFields(false);
                  })
                  .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    handleError(errorMessage);
                  })
                  .then(async () => {
                    await createOrder(userConecs, order_amount, paygreenShop);
                  })
              } else {
                userConecs = {
                  buyerId: userBankCard.data.id,
                  email: userBankCard.data.email,
                  firstName: userBankCard.data.firstName, //prénom
                  lastName: userBankCard.data.lastName, //nom
                  instrument: event.detail.instrument.id,
                  balance: event.detail.instrument.daily_balance,
                  brand: event.detail.instrument.issuer,
                  reuse: event.detail.instrument.reuse_allowed,
                  last4: event.detail.instrument.last4
                }
                await window.paygreenjs.unmount(true);
                setShowPaymentFields(false);
                await createOrder(userConecs, order_amount, paygreenShop);
              }
            }
          }
        );
        window.paygreenjs.attachEventListener(window.paygreenjs.Events.TOKEN_FAIL, (event) => {
          handleError(WsError.PAYGREEN_TOKEN_FAIL);
          setSave(false)
        })
      }
    } catch (e) {
      console.error(WsError.PAYGREEN_GLOBAL_FAIL, e);
      handleError(WsError.PAYGREEN_GLOBAL_FAIL);
    }
  }

  const createOrder = async (paygreenUser, order_amount, paygreenShop) => {
    paygreenCreateOrder(paygreenUser, order_amount, paygreenShop, axiosInstance)
      .then(async (response) => {
        const conecsOrder = response.data.data;

        console.log('handleSubmit paygreenCreateBuyer conecsOrder', conecsOrder);

        try {
          //CONECS
          await window.paygreenjs.init({
                                         paymentOrderID: conecsOrder.id,
                                         objectSecret: conecsOrder.object_secret,
                                         mode: 'payment',
                                         publicKey: paygreen,
                                         instrument: paygreenUser.instrument,
                                       })

          window.paygreenjs.attachEventListener(
            window.paygreenjs.Events.PAYMENT_FAIL,
            (event) => {
              setErrorMessage(WsError.PAYGREEN_CALL_ERROR);
              setLoading(false);
              initPaygreen();
            },
          );
          handlePaymentDone(conecsOrder.id, paygreenUser, true);
        } catch (error) {
          console.error(WsError.PAYGREEN_INIT_ERRROR, error);
          handleError(WsError.PAYGREEN_INIT_ERRROR);
        }
      })
      .catch((error) => {
        console.error(WsError.PAYGREEN_CREATE_ORDER_ERROR, error);
        const errorMessage = getErrorMessage(error);
        handleError(errorMessage);
      })
  }

  useEffect(() => {
    if (cartItems.isPaid === true) {
      setLoading(false)
    }
  }, [cartItems.isPaid]);

  const handleError = (message) => {
    setErrorMessage(message || 'Paiement échoué');
    setLoading(false);
    stopPaymentLoading();
    window.paygreenjs.unmount(true);
    initPaygreen();
    onInitPaygreenEventListeners();
    paymentEventTriggered = false;
  };

  const handlePaymentDone = (paygreenOrderId, paygreenUser, checkSave) => {
    window.paygreenjs.attachEventListener(
      window.paygreenjs.Events.FULL_PAYMENT_DONE,
      (event) => {
        if (paymentEventTriggered) {
          return;
        }
        paymentEventTriggered = true;

        paygreenCapture(axiosInstance, paygreenOrderId, order._id, order.storeId, userEmail)
          .then((currentOrder) => {
            if(!user || !user.email){
              user.email = userEmail
            }
            dispatch(successPaidOrder(currentOrder.data));
            dispatch(addUsedPromo(user, axiosInstance, cartItems));
            window.paygreenjs.unmount(true);
            if (checkSave && save) {
              if (isConecs) {
                dispatch(getConecsUserinfo(paygreenUser, user, axiosInstance));
              } else {
                dispatch(storePaygreenBankCardInfo(paygreenUser, user, axiosInstance));
              }
            }
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error);
            handleError(errorMessage);
          });
      },
    );
  }

  return (
    <>
      <>
        <script defer type="text/javascript" src={paygreen_endpoint} />
        <link href={paygreenJs} type="text/css" rel="stylesheet" />
        {conecs && conecs.buyerId && isConecs ? (
          <>
            <div id="paygreen-container" style={{ display: 'none' }} />
            <div className="flex items-center formcarte text-black px-8 pr-14 w-full" onClick={handleCheckboxChange}>
              <input
                id="conecsCheckbox"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled
              />
              <label className="conecslabel mr-4" htmlFor="conecsCheckbox" />
              <div className="h-full flex items-center justify-between ">
                <img src={conecs.img} alt="" className="h-14 pr-8" />
                <span className=" text-lg font-semibold bg-red-100  p-1 px-2 rounded-[5px] text-red-600 ">
                    <span className="geomanistBold">{(conecs.balance) / 100}</span>€ max
                  </span>
              </div>
              <button
                onClick={handleRemovePaymentMethod}
                className="deletepayment"
                style={{ marginLeft: 'auto' }}
              >
                <img src={dell} alt="" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={`flex items-center  formcarte text-black px-8 pr-14 w-full ${
              isContentVisible ? 'slide-down-btn open' : ''
            }`} onClick={handleCheckboxChange}>
              <div className="flex w-[70%] items-center">
                <input
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  className="paygreencheckbox"
                  id="paygreenCheckbox"
                  checked={isChecked}
                  disabled
                />
                <label htmlFor="paygreenCheckbox" className="paygreenlabel mr-4" />
                <div className="text-[16px]" style={{ fontFamily: 'Open Sans' }}>{label}</div>
                <div className="paygreenimg h-full" />
              </div>
              <div className="cbimg flex gap-2 no-wrap">{logos}</div>
            </div>
            {isContentVisible && (
              <>
                {isConecs
                  ? (
                    <PaygreenConecsForm
                      user={user}
                      userEmail={userEmail}
                      userName={userName}
                      setUserName={setUserName}
                      setUserEmail={setUserEmail}
                      handleSaveCard={handleSaveCard}
                      errorMessage={errorMessage}
                      loading={loading}
                      cardNbComplete={cardNbComplete}
                      cardExpComplete={cardExpComplete}
                      cardCVCComplete={cardCVCComplete}
                      cartItems={cartItems}
                      onInitPaygreenEventListeners={onInitPaygreenEventListeners}
                      initPaygreen={initPaygreen}
                      paymentEventTriggered={paymentEventTriggered}
                      conecs={conecs}
                      handleSubmit={handleSubmit}
                      save={save}
                      paymentInputLoading={paymentInputLoading}
                      showPaymentFields={showPaymentFields}
                    />
                  )
                  : (
                    <PaygreenUserForm
                      user={user}
                      userEmail={userEmail}
                      userName={userName}
                      setUserName={setUserName}
                      setUserEmail={setUserEmail}
                      handleSaveCard={handleSaveCard}
                      errorMessage={errorMessage}
                      loading={loading}
                      cardNbComplete={cardNbComplete}
                      cardExpComplete={cardExpComplete}
                      cardCVCComplete={cardCVCComplete}
                      cartItems={cartItems}
                      onInitPaygreenEventListeners={onInitPaygreenEventListeners}
                      initPaygreen={initPaygreen}
                      paymentEventTriggered={paymentEventTriggered}
                      conecs={conecs}
                      handleSubmit={handleSubmit}
                      save={save}
                      paymentInputLoading={paymentInputLoading}
                      showPaymentFields={showPaymentFields}
                      createBuyer={createBuyer}
                    />
                  )
                }
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
export default forwardRef(PaygreenGlobal);

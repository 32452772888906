import React from "react";

const HomeLoader = () => {
  return (
    <>
      <div className="bg-white relative z-50">
        <div className="loader_container">
          <div className="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="193"
              height="850"
              viewBox="0 0 193 335"
              fill="none"
              className="scale-50"
            >
              <path
                className="clipLeaf"
                d="M12.4903 79.2755L12.3538 77.2802L10.6142 77.3992L10.495 79.1388L12.4903 79.2755ZM12.4903 79.2755C12.3538 77.2802 12.3552 77.2801 12.3567 77.28L12.3612 77.2797L12.3749 77.2788L12.4211 77.276C12.4603 77.2736 12.5159 77.2705 12.5875 77.2668C12.7308 77.2595 12.9381 77.2502 13.2052 77.2415C13.7393 77.224 14.513 77.209 15.4921 77.2171C17.4495 77.2333 20.2328 77.3419 23.569 77.7101C30.231 78.4454 39.155 80.2218 48.1213 84.4063C57.1009 88.5969 66.1438 95.2142 72.9629 105.62C79.7845 116.031 84.2951 130.102 84.4153 149.059L84.4282 151.084L82.4027 151.072C63.4148 150.951 49.3203 146.45 38.8924 139.641C28.468 132.835 21.8379 123.809 17.6384 114.845C13.4452 105.894 11.6645 96.9856 10.927 90.3351C10.5578 87.0047 10.4486 84.2261 10.4321 82.272C10.4239 81.2946 10.4389 80.5223 10.4563 79.989C10.465 79.7223 10.4743 79.5154 10.4816 79.3723C10.4853 79.3007 10.4884 79.2451 10.4908 79.2061L10.4936 79.1599L10.4945 79.1462L10.4948 79.1417C10.4949 79.1401 10.495 79.1388 12.4903 79.2755Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="clipLeaf"
                d="M95.2161 5.92224L96.5288 4.41332L95.2159 3.2711L93.9031 4.41359L95.2161 5.92224ZM95.2161 5.92224C93.9031 4.41359 93.9021 4.41445 93.901 4.41549L93.8976 4.41844L93.8873 4.42748L93.8525 4.4581C93.8232 4.48407 93.7816 4.52111 93.7284 4.5691C93.6219 4.66507 93.4687 4.80482 93.2737 4.98718C92.8838 5.35187 92.3262 5.88726 91.6397 6.58405C90.2674 7.97707 88.3766 10.0184 86.2789 12.6336C82.0899 17.8556 77.0391 25.4107 73.6639 34.6988C70.2837 44.0009 68.5776 55.0636 71.1271 67.2359C73.6774 79.4123 80.4543 92.5461 93.794 106.036L95.2158 107.474L96.638 106.036C109.983 92.5464 116.763 79.4126 119.314 67.236C121.865 55.0635 120.158 44.0007 116.776 34.6986C113.4 25.4104 108.347 17.8553 104.156 12.6332C102.058 10.0181 100.166 7.97677 98.7931 6.58376C98.1064 5.88697 97.5486 5.35158 97.1585 4.98691C96.9634 4.80454 96.8102 4.6648 96.7037 4.56883C96.6504 4.52084 96.6088 4.48379 96.5795 4.45783L96.5447 4.42721L96.5344 4.41817L96.531 4.41522C96.5298 4.41418 96.5288 4.41332 95.2161 5.92224Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="clipLeaf"
                d="M179.897 79.2755L181.892 79.1385L181.773 77.3992L180.033 77.2802L179.897 79.2755ZM179.897 79.2755C181.892 79.1385 181.892 79.1398 181.892 79.1414L181.893 79.1459L181.894 79.1596L181.897 79.2058C181.899 79.2448 181.902 79.3004 181.906 79.372C181.913 79.515 181.922 79.722 181.931 79.9887C181.949 80.522 181.964 81.2943 181.956 82.2717C181.939 84.2259 181.831 87.0044 181.462 90.3348C180.725 96.9854 178.945 105.894 174.753 114.845C170.554 123.809 163.924 132.835 153.499 139.641C143.071 146.45 128.975 150.951 109.985 151.072L107.959 151.084L107.972 149.059C108.095 130.102 112.607 116.03 119.429 105.62C126.249 95.2142 135.291 88.597 144.27 84.4063C153.236 80.2218 162.159 78.4454 168.82 77.7101C172.156 77.3419 174.938 77.2333 176.896 77.2171C177.875 77.209 178.648 77.224 179.182 77.2415C179.449 77.2502 179.657 77.2595 179.8 77.2668C179.871 77.2705 179.927 77.2736 179.966 77.276L180.012 77.2788L180.026 77.2797L180.031 77.28C180.032 77.2801 180.033 77.2802 179.897 79.2755Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="clip"
                d="M129.802 334.342V119.193C129.168 119.15 128.545 119.15 127.91 119.15H64.6276V334.342H129.802ZM48.4092 334.342H64.6276V119.193H64.2729C38.0483 119.193 15.0266 140.591 13.135 166.719L4.26809 286.869C2.32274 312.943 22.1954 334.342 48.4092 334.342ZM143.763 334.342C169.988 334.342 189.85 312.943 187.915 286.815L179.038 166.676C177.146 141.183 155.167 120.192 129.759 119.193V334.342H143.763Z"
                fill="#FFDE59"
              />
              <path
                d="M0 155.445C0 132.313 18.7521 113.561 41.8841 113.561V113.561C65.016 113.561 83.7681 132.313 83.7681 155.445V155.445C83.7681 178.577 65.016 197.329 41.8841 197.329V197.329C18.7521 197.329 0 178.577 0 155.445V155.445Z"
                fill="white"
              />
              <path
                d="M15.7708 155.44C15.7708 141.016 27.4638 129.317 41.8878 129.317V129.317C56.3119 129.317 68.0049 141.016 68.0049 155.44V155.44C68.0049 169.864 56.3119 181.562 41.8878 181.562V181.562C27.4638 181.562 15.7708 169.864 15.7708 155.44V155.44Z"
                fill="#3D1F1E"
              />
              <path
                d="M38.96 148.314C38.96 142.761 43.4615 138.259 49.0145 138.259V138.259C54.5675 138.259 59.069 142.761 59.069 148.314V148.314C59.069 153.867 54.5675 158.368 49.0145 158.368V158.368C43.4615 158.368 38.96 153.867 38.96 148.314V148.314Z"
                fill="white"
              />
              <path
                d="M108.458 155.445C108.458 132.313 127.21 113.561 150.342 113.561V113.561C173.474 113.561 192.226 132.313 192.226 155.445V155.445C192.226 178.577 173.474 197.329 150.342 197.329V197.329C127.21 197.329 108.458 178.577 108.458 155.445V155.445Z"
                fill="white"
              />
              <path
                d="M124.213 155.44C124.213 141.013 135.908 129.317 150.335 129.317V129.317C164.763 129.317 176.458 141.013 176.458 155.44V155.44C176.458 169.867 164.763 181.562 150.335 181.562V181.562C135.908 181.562 124.213 169.867 124.213 155.44V155.44Z"
                fill="#3D1F1E"
              />
              <path
                d="M148.265 148.314C148.265 142.761 152.767 138.259 158.32 138.259V138.259C163.873 138.259 168.374 142.761 168.374 148.314V148.314C168.374 153.867 163.873 158.368 158.32 158.368V158.368C152.767 158.368 148.265 153.867 148.265 148.314V148.314Z"
                fill="white"
              />
              <mask
                id="mask0_6_2"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="66"
                y="223"
                width="61"
                height="68"
              >
                <path
                  d="M66.8094 223.178H126.073V290.437H66.8094V223.178Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_6_2)" className="clip">
                <path
                  d="M96.441 308.053C94.4957 308.053 92.5718 307.784 90.6695 307.236C88.7564 306.699 86.9078 305.893 85.1129 304.829C83.318 303.754 81.6091 302.453 79.9969 300.906C78.374 299.358 76.8801 297.595 75.5044 295.628C74.1287 293.651 72.9034 291.512 71.8286 289.191C70.7431 286.88 69.8296 284.429 69.088 281.85C68.3464 279.281 67.7875 276.626 67.4006 273.896C67.0244 271.156 66.8417 268.404 66.8417 265.61C66.8417 262.826 67.0244 260.064 67.4006 257.334C67.7875 254.604 68.3464 251.95 69.088 249.37C69.8296 246.801 70.7431 244.351 71.8286 242.04C72.9034 239.719 74.1287 237.58 75.5044 235.602C76.8801 233.635 78.374 231.873 79.9969 230.325C81.6091 228.777 83.318 227.477 85.1129 226.402C86.9078 225.338 88.7564 224.532 90.6695 223.995C92.5718 223.446 94.4957 223.178 96.441 223.178C98.3864 223.178 100.31 223.446 102.213 223.995C104.126 224.532 105.974 225.338 107.769 226.402C109.564 227.477 111.273 228.777 112.885 230.325C114.508 231.873 116.002 233.635 117.378 235.602C118.753 237.58 119.979 239.719 121.053 242.04C122.139 244.351 123.053 246.801 123.794 249.37C124.536 251.95 125.095 254.604 125.482 257.334C125.858 260.064 126.04 262.826 126.04 265.61C126.04 268.404 125.858 271.156 125.482 273.896C125.095 276.626 124.536 279.281 123.794 281.85C123.053 284.429 122.139 286.88 121.053 289.191C119.979 291.512 118.753 293.651 117.378 295.628C116.002 297.595 114.508 299.358 112.885 300.906C111.273 302.453 109.564 303.754 107.769 304.829C105.974 305.893 104.126 306.699 102.213 307.236C100.31 307.784 98.3864 308.053 96.441 308.053Z"
                  fill="#3D1F1E"
                />
              </g>
              <path
                className="clip"
                d="M121.442 286.385C121.442 287.589 121.27 288.782 120.948 289.975C120.614 291.157 120.131 292.307 119.486 293.425C118.841 294.543 118.056 295.596 117.121 296.607C116.186 297.606 115.122 298.541 113.929 299.39C112.747 300.25 111.446 301.013 110.049 301.68C108.652 302.357 107.18 302.916 105.621 303.378C104.063 303.84 102.461 304.194 100.817 304.431C99.1618 304.667 97.4959 304.786 95.8192 304.786C94.1318 304.786 92.4659 304.667 90.8215 304.431C89.1664 304.194 87.5649 303.84 86.0065 303.378C84.4588 302.916 82.9756 302.357 81.5784 301.68C80.1812 301.013 78.8807 300.25 77.6985 299.39C76.5055 298.541 75.4414 297.606 74.5064 296.607C73.5713 295.596 72.7867 294.543 72.1419 293.425C71.497 292.307 71.0134 291.157 70.6802 289.975C70.3578 288.782 70.1965 287.589 70.1965 286.385C70.1965 285.171 70.3578 283.978 70.6802 282.785C71.0134 281.603 71.497 280.453 72.1419 279.335C72.7867 278.217 73.5713 277.164 74.5064 276.154C75.4414 275.154 76.5055 274.219 77.6985 273.37C78.8807 272.51 80.1812 271.747 81.5784 271.081C82.9756 270.403 84.4588 269.845 86.0065 269.382C87.5649 268.92 89.1664 268.566 90.8215 268.329C92.4659 268.093 94.1318 267.974 95.8192 267.974C97.4959 267.974 99.1618 268.093 100.817 268.329C102.461 268.566 104.063 268.92 105.621 269.382C107.18 269.845 108.652 270.403 110.049 271.081C111.446 271.747 112.747 272.51 113.929 273.37C115.122 274.219 116.186 275.154 117.121 276.154C118.056 277.164 118.841 278.217 119.486 279.335C120.131 280.453 120.614 281.603 120.948 282.785C121.27 283.978 121.442 285.171 121.442 286.385Z"
                fill="#FA5B5B"
              />
            </svg>
          </div>
          <div className="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="193"
              height="335"
              viewBox="0 0 193 335"
              fill="none"
              className="scale-50"
            >
              <path
                className="logo-back"
                d="M12.4903 79.2755L12.3538 77.2802L10.6142 77.3992L10.495 79.1388L12.4903 79.2755ZM12.4903 79.2755C12.3538 77.2802 12.3552 77.2801 12.3567 77.28L12.3612 77.2797L12.3749 77.2788L12.4211 77.276C12.4603 77.2736 12.5159 77.2705 12.5875 77.2668C12.7308 77.2595 12.9381 77.2502 13.2052 77.2415C13.7393 77.224 14.513 77.209 15.4921 77.2171C17.4495 77.2333 20.2328 77.3419 23.569 77.7101C30.231 78.4454 39.155 80.2218 48.1213 84.4063C57.1009 88.5969 66.1438 95.2142 72.9629 105.62C79.7845 116.031 84.2951 130.102 84.4153 149.059L84.4282 151.084L82.4027 151.072C63.4148 150.951 49.3203 146.45 38.8924 139.641C28.468 132.835 21.8379 123.809 17.6384 114.845C13.4452 105.894 11.6645 96.9856 10.927 90.3351C10.5578 87.0047 10.4486 84.2261 10.4321 82.272C10.4239 81.2946 10.4389 80.5223 10.4563 79.989C10.465 79.7223 10.4743 79.5154 10.4816 79.3723C10.4853 79.3007 10.4884 79.2451 10.4908 79.2061L10.4936 79.1599L10.4945 79.1462L10.4948 79.1417C10.4949 79.1401 10.495 79.1388 12.4903 79.2755Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="logo-back"
                d="M95.2161 5.92224L96.5288 4.41332L95.2159 3.2711L93.9031 4.41359L95.2161 5.92224ZM95.2161 5.92224C93.9031 4.41359 93.9021 4.41445 93.901 4.41549L93.8976 4.41844L93.8873 4.42748L93.8525 4.4581C93.8232 4.48407 93.7816 4.52111 93.7284 4.5691C93.6219 4.66507 93.4687 4.80482 93.2737 4.98718C92.8838 5.35187 92.3262 5.88726 91.6397 6.58405C90.2674 7.97707 88.3766 10.0184 86.2789 12.6336C82.0899 17.8556 77.0391 25.4107 73.6639 34.6988C70.2837 44.0009 68.5776 55.0636 71.1271 67.2359C73.6774 79.4123 80.4543 92.5461 93.794 106.036L95.2158 107.474L96.638 106.036C109.983 92.5464 116.763 79.4126 119.314 67.236C121.865 55.0635 120.158 44.0007 116.776 34.6986C113.4 25.4104 108.347 17.8553 104.156 12.6332C102.058 10.0181 100.166 7.97677 98.7931 6.58376C98.1064 5.88697 97.5486 5.35158 97.1585 4.98691C96.9634 4.80454 96.8102 4.6648 96.7037 4.56883C96.6504 4.52084 96.6088 4.48379 96.5795 4.45783L96.5447 4.42721L96.5344 4.41817L96.531 4.41522C96.5298 4.41418 96.5288 4.41332 95.2161 5.92224Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="logo-back"
                d="M179.897 79.2755L181.892 79.1385L181.773 77.3992L180.033 77.2802L179.897 79.2755ZM179.897 79.2755C181.892 79.1385 181.892 79.1398 181.892 79.1414L181.893 79.1459L181.894 79.1596L181.897 79.2058C181.899 79.2448 181.902 79.3004 181.906 79.372C181.913 79.515 181.922 79.722 181.931 79.9887C181.949 80.522 181.964 81.2943 181.956 82.2717C181.939 84.2259 181.831 87.0044 181.462 90.3348C180.725 96.9854 178.945 105.894 174.753 114.845C170.554 123.809 163.924 132.835 153.499 139.641C143.071 146.45 128.975 150.951 109.985 151.072L107.959 151.084L107.972 149.059C108.095 130.102 112.607 116.03 119.429 105.62C126.249 95.2142 135.291 88.597 144.27 84.4063C153.236 80.2218 162.159 78.4454 168.82 77.7101C172.156 77.3419 174.938 77.2333 176.896 77.2171C177.875 77.209 178.648 77.224 179.182 77.2415C179.449 77.2502 179.657 77.2595 179.8 77.2668C179.871 77.2705 179.927 77.2736 179.966 77.276L180.012 77.2788L180.026 77.2797L180.031 77.28C180.032 77.2801 180.033 77.2802 179.897 79.2755Z"
                fill="#71B261"
                stroke="#71B261"
                stroke-width="4"
              />
              <path
                className="logo-back"
                d="M129.802 334.342V119.193C129.168 119.15 128.545 119.15 127.91 119.15H64.6276V334.342H129.802ZM48.4092 334.342H64.6276V119.193H64.2729C38.0483 119.193 15.0266 140.591 13.135 166.719L4.26809 286.869C2.32274 312.943 22.1954 334.342 48.4092 334.342ZM143.763 334.342C169.988 334.342 189.85 312.943 187.915 286.815L179.038 166.676C177.146 141.183 155.167 120.192 129.759 119.193V334.342H143.763Z"
                fill="#FFDE59"
              />
              <path
                d="M0 155.445C0 132.313 18.7521 113.561 41.8841 113.561V113.561C65.016 113.561 83.7681 132.313 83.7681 155.445V155.445C83.7681 178.577 65.016 197.329 41.8841 197.329V197.329C18.7521 197.329 0 178.577 0 155.445V155.445Z"
                fill="white"
              />
              <path
                d="M15.7708 155.44C15.7708 141.016 27.4638 129.317 41.8878 129.317V129.317C56.3119 129.317 68.0049 141.016 68.0049 155.44V155.44C68.0049 169.864 56.3119 181.562 41.8878 181.562V181.562C27.4638 181.562 15.7708 169.864 15.7708 155.44V155.44Z"
                fill="#3D1F1E"
              />
              <path
                d="M38.96 148.314C38.96 142.761 43.4615 138.259 49.0145 138.259V138.259C54.5675 138.259 59.069 142.761 59.069 148.314V148.314C59.069 153.867 54.5675 158.368 49.0145 158.368V158.368C43.4615 158.368 38.96 153.867 38.96 148.314V148.314Z"
                fill="white"
              />
              <path
                d="M108.458 155.445C108.458 132.313 127.21 113.561 150.342 113.561V113.561C173.474 113.561 192.226 132.313 192.226 155.445V155.445C192.226 178.577 173.474 197.329 150.342 197.329V197.329C127.21 197.329 108.458 178.577 108.458 155.445V155.445Z"
                fill="white"
              />
              <path
                d="M124.213 155.44C124.213 141.013 135.908 129.317 150.335 129.317V129.317C164.763 129.317 176.458 141.013 176.458 155.44V155.44C176.458 169.867 164.763 181.562 150.335 181.562V181.562C135.908 181.562 124.213 169.867 124.213 155.44V155.44Z"
                fill="#3D1F1E"
              />
              <path
                d="M148.265 148.314C148.265 142.761 152.767 138.259 158.32 138.259V138.259C163.873 138.259 168.374 142.761 168.374 148.314V148.314C168.374 153.867 163.873 158.368 158.32 158.368V158.368C152.767 158.368 148.265 153.867 148.265 148.314V148.314Z"
                fill="white"
              />
              <mask
                id="mask0_6_2"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="66"
                y="223"
                width="61"
                height="68"
              >
                <path
                  d="M66.8094 223.178H126.073V290.437H66.8094V223.178Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_6_2)">
                <path
                  d="M96.441 308.053C94.4957 308.053 92.5718 307.784 90.6695 307.236C88.7564 306.699 86.9078 305.893 85.1129 304.829C83.318 303.754 81.6091 302.453 79.9969 300.906C78.374 299.358 76.8801 297.595 75.5044 295.628C74.1287 293.651 72.9034 291.512 71.8286 289.191C70.7431 286.88 69.8296 284.429 69.088 281.85C68.3464 279.281 67.7875 276.626 67.4006 273.896C67.0244 271.156 66.8417 268.404 66.8417 265.61C66.8417 262.826 67.0244 260.064 67.4006 257.334C67.7875 254.604 68.3464 251.95 69.088 249.37C69.8296 246.801 70.7431 244.351 71.8286 242.04C72.9034 239.719 74.1287 237.58 75.5044 235.602C76.8801 233.635 78.374 231.873 79.9969 230.325C81.6091 228.777 83.318 227.477 85.1129 226.402C86.9078 225.338 88.7564 224.532 90.6695 223.995C92.5718 223.446 94.4957 223.178 96.441 223.178C98.3864 223.178 100.31 223.446 102.213 223.995C104.126 224.532 105.974 225.338 107.769 226.402C109.564 227.477 111.273 228.777 112.885 230.325C114.508 231.873 116.002 233.635 117.378 235.602C118.753 237.58 119.979 239.719 121.053 242.04C122.139 244.351 123.053 246.801 123.794 249.37C124.536 251.95 125.095 254.604 125.482 257.334C125.858 260.064 126.04 262.826 126.04 265.61C126.04 268.404 125.858 271.156 125.482 273.896C125.095 276.626 124.536 279.281 123.794 281.85C123.053 284.429 122.139 286.88 121.053 289.191C119.979 291.512 118.753 293.651 117.378 295.628C116.002 297.595 114.508 299.358 112.885 300.906C111.273 302.453 109.564 303.754 107.769 304.829C105.974 305.893 104.126 306.699 102.213 307.236C100.31 307.784 98.3864 308.053 96.441 308.053Z"
                  className="logo-back"
                  fill="#3D1F1E"
                />
              </g>
              <path
                d="M121.442 286.385C121.442 287.589 121.27 288.782 120.948 289.975C120.614 291.157 120.131 292.307 119.486 293.425C118.841 294.543 118.056 295.596 117.121 296.607C116.186 297.606 115.122 298.541 113.929 299.39C112.747 300.25 111.446 301.013 110.049 301.68C108.652 302.357 107.18 302.916 105.621 303.378C104.063 303.84 102.461 304.194 100.817 304.431C99.1618 304.667 97.4959 304.786 95.8192 304.786C94.1318 304.786 92.4659 304.667 90.8215 304.431C89.1664 304.194 87.5649 303.84 86.0065 303.378C84.4588 302.916 82.9756 302.357 81.5784 301.68C80.1812 301.013 78.8807 300.25 77.6985 299.39C76.5055 298.541 75.4414 297.606 74.5064 296.607C73.5713 295.596 72.7867 294.543 72.1419 293.425C71.497 292.307 71.0134 291.157 70.6802 289.975C70.3578 288.782 70.1965 287.589 70.1965 286.385C70.1965 285.171 70.3578 283.978 70.6802 282.785C71.0134 281.603 71.497 280.453 72.1419 279.335C72.7867 278.217 73.5713 277.164 74.5064 276.154C75.4414 275.154 76.5055 274.219 77.6985 273.37C78.8807 272.51 80.1812 271.747 81.5784 271.081C82.9756 270.403 84.4588 269.845 86.0065 269.382C87.5649 268.92 89.1664 268.566 90.8215 268.329C92.4659 268.093 94.1318 267.974 95.8192 267.974C97.4959 267.974 99.1618 268.093 100.817 268.329C102.461 268.566 104.063 268.92 105.621 269.382C107.18 269.845 108.652 270.403 110.049 271.081C111.446 271.747 112.747 272.51 113.929 273.37C115.122 274.219 116.186 275.154 117.121 276.154C118.056 277.164 118.841 278.217 119.486 279.335C120.131 280.453 120.614 281.603 120.948 282.785C121.27 283.978 121.442 285.171 121.442 286.385Z"
                fill="#FA5B5B"
                className="logo-back"
              />
            </svg>
          </div>

      
          {/* <div className="flex relative overflow-hidden h-10">
            <div className="info1 info ext-2xl font-bol">Chargement en cours....Préparez vous à être surpris !</div>
           <div className="info2 info ext-2xl font-bol">Les pixels s'alignent pour vous offrir une expérience unique...</div> 
            <div className="info info3 ext-2xl font-bol">La symphonie des octets commence a se mettre en place...</div>
           <div className="info4 info ext-2xl font-bol">Accordez nous un instant pour vous evitez la queue à la caisse...</div> 
          </div> */}
        </div>
     
      </div>
      
    </>
  );
};

export default HomeLoader;

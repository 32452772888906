import React, { useEffect, useState } from 'react';
import useAxiosInterceptors from '../../axios/useAxios';
import { getUserEmail } from '../../utils/StringUtils';
import { getOrderAmount } from '../../utils/PriceUtils';
import { useDispatch, useSelector } from 'react-redux';
import { log } from 'qrcode/lib/core/galois-field';
import { successPaidOrder } from '../../actions/cartActions';
import { addUsedPromo } from '../../actions/userActions';
import { notifyDoubleCallback, notifyError } from '../../utils/Notify';
import promo from '../../components/promo';

const PayzenInit = ({ onPayzenInitialized, onPayzenError, onPaymentLoading, order, promoLoaded, setPayzenToken }) => {

  const dispatch = useDispatch()
  const axiosInstance = useAxiosInterceptors();
  const user = useSelector((state) => state.user);

  const dispatchData = (data, payzenSavedCard) => {
    dispatch(successPaidOrder(data));
    dispatch(addUsedPromo(user, axiosInstance, order, !!payzenSavedCard.pm, payzenSavedCard));
  };

  const updateOrder = (email, paymentIntent, paymentMethod, payzenSavedCard) => {
    console.log('PAYZEN SAVED CARD', payzenSavedCard)
    axiosInstance.put(`/orders/${order._id}/pay`, {
      userEmail: email, paymentIntent, paymentMethod, creditCard: payzenSavedCard
    })
                 .then((response) => {
                   if (!user || !user.email) {
                     user.email = email
                   }
                   dispatchData(response.data, payzenSavedCard);
                 })
                 .catch((error) => {
                   console.error({ error });
                 })
  };

  const initializePayzenListeners = async (email, token) => {
    await window.KR.setFormConfig({ formToken: token, 'kr-language': 'fr-FR' });
    window.KR.onError(error => {
      console.log(error)
      onPaymentLoading(false)
      const excludedErrorCodes = ['CLIENT_101'];
      if (error.errorCode && !excludedErrorCodes.includes(error.errorCode)) {
        console.error('Current payzen error code', error.errorCode);
        if (error.errorCode === 'CLIENT_309' && error.field === 'cardHolderMail') onPayzenError('Informations email incorrectes')
        else if (error.errorMessage !== 'Apple Pay - Paiement refusé') onPayzenError(error.errorMessage);
        else notifyError(error.errorMessage, '*Nous n\'acceptons pas les titres restaurant via Apple Pay');
      }
    })
    await window.KR.button.onClick(() => {
      onPaymentLoading(true)
    })

    window.KR.onSubmit(async (response) => {

      const isPaid = response.clientAnswer.orderStatus === 'PAID';

      if (!isPaid || response.clientAnswer.transactions.length === 0) {
        console.error('An error occured during payzen payment');
        return;
      }
      console.log(response)
      const transaction = response.clientAnswer.transactions[0];
      const paymentMethodToken = transaction.paymentMethodToken;
      const cardHolderPan = transaction.transactionDetails.cardDetails.cardHolderPan;
      const effectiveBrand = transaction.transactionDetails.cardDetails.effectiveBrand;
      const isApplePay = transaction.transactionDetails.wallet === 'APPLE_PAY';
      const last4digit = cardHolderPan.slice(-4);
      email = email || response.clientAnswer.customer.email || undefined
      const payzenSavedCard = !isApplePay && paymentMethodToken
        ? {
          pm: paymentMethodToken,
          last4: last4digit,
          brand: effectiveBrand.toLowerCase(),
          isPayzen: true
        }
        : {
          brand: effectiveBrand.toLowerCase(),
          last4: last4digit,
        };
      console.log('PAYZEN', payzenSavedCard)
      const paymentIntent = {
        id: response.clientAnswer.orderDetails.orderId,
        status: 'succeeded',
      };
      const paymentMethod = isApplePay ? 'applepay' : 'cb';
      await updateOrder(email, paymentIntent, paymentMethod, payzenSavedCard);

    })

    window.KR.onFormValid((event) => {
      onPayzenError('')
    })
  }

  const initLib = (token) => {

    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"]`);

      if (existingScript) {
        existingScript.parentNode.removeChild(existingScript);
      }

      const script = document.createElement('script');
      script.src = 'https://static.payzen.eu/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js';
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.setAttribute('kr-public-key', process.env.REACT_APP_PAYZEN_PUBLIC_KEY);
      script.setAttribute('kr-language', 'fr-FR');
      // script.setAttribute('kr-hide-debug-toolbar', true);
      script.onload = async (event) => {
        console.log('Payzen script loaded:', event);
        await initializePayzenListeners(user?.email, token)

        resolve();
      };
      script.onerror = (error) => {
        console.error('Error loading Payzen script:', error);
        reject(error);
      };
      document.body.appendChild(script);
    });
  };

  const initializePayzen = () => {
    const email = getUserEmail(user);
    console.log('INIT PAYZEN ORDER AMOUNT', order)
    axiosInstance.post('/payzen/getToken',
                       {
                         amount: getOrderAmount(order),
                         orderId: order._id,
                         email
                       })
                 .then(async (response) => {
                   const token = response.data;
                   localStorage.setItem('token', JSON.stringify(token));
                   setPayzenToken(token)
                   initLib(token.paymentToken);
                 })
                 .catch((error) => {
                   console.log({ error })
                   window.location.reload();
                 });
  };
  useEffect(() => {
    if (promoLoaded) initializePayzen();
  }, [promoLoaded]);

  return null;
};

export default PayzenInit;

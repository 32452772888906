import React from 'react';
import fidCard from '../../styles/img/menu/fid-card.png';
import newsPaper from '../../styles/img/menu/news-paper.png';
import { useNavigate } from 'react-router';
import { ACCOUNT, HOME, TICKETS, USER_FID_CARD } from '../../constants/routes';
import StoreSelectionButton from '../button/StoreSelectionButton';
import BackButton from '../button/BackButton';
import ProfilBox from '../card/ProfilBox';
import { disconnectUser } from '../../actions/userActions';
import { useDispatch } from 'react-redux';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';
import { notifySuccess } from '../../utils/Notify';

const NavigationMenu = ({ isOpen, onClose, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleSignOut = async() => {
    dispatch(disconnectUser())
    await signOut(auth)
    localStorage.removeItem('popupUser')
    notifySuccess(
      "Tu as été déconnecté ...",
      "Nous espérons te revoir très vite !",
      "D'accord",
      () => navigate(HOME)
    )
  }

  const goToProfile = () => {
    // TODO
  };
  return (
    <>
      {isOpen && (<div className="mask" onClick={onClose} />)}
      <div className={`navigation-menu ${isOpen ? 'open' : ''}`}>
        <div className="menu">
          <div className='flex justify-between	'>
            <BackButton onClick={onClose} />
            <button className='deconnect-button' onClick={() => handleSignOut()}>Déconnexion</button>
          </div>
          <ProfilBox user={user} click={goToProfile} />
          <div className="navigation-liste">
              <div className="navigation-item" onClick={() => navigate(USER_FID_CARD)}>
                <img className="nav-icon" src={fidCard} alt="" />
                <div className="navigation-label">Cartes fidélités</div>
              </div>
              <div className="navigation-item" onClick={() => navigate(TICKETS)}>
                <img className="nav-icon" src={newsPaper} alt="" />
                <div className="navigation-label">Mes tickets</div>
              </div>
              {/*<div className="navigation-item"  onClick={() => navigate(WALLET)}>*/}
              {/*  <img className="nav-icon" src={wallet} alt="" />*/}
              {/*  <div className="navigation-label">My wallet</div>*/}
              {/*</div>*/}
          </div>
          {/*<div className="navigation-item-help">*/}
          {/*  <img className="nav-icon" src={question} alt="" />*/}
          {/*  <div*/}
          {/*    className="navigation-label"*/}
          {/*  >*/}
          {/*    Besoin d'aide ?*/}
          {/*  </div>*/}
          {/*</div>*/}
          <StoreSelectionButton originPath={ACCOUNT} />
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;

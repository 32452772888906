import { notifyError, notifyWarning } from '../utils/Notify';
import { getErrorMessage } from '../utils/WsUtils';
import { WsError } from '../constants/wsError';

export const identify = (axiosInstance, userInfo, order, fidCardNum, recaptchaResultToken, onSuccess, onFinally) => {
  axiosInstance.post('/apiws/user/identification', {
    userInfo, order, fidCardNum, recaptchaResultToken
  })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      console.error('Add card failed', error);
      if (error?.response?.data?.errorCode === 'RECAPTCHA_ASSESMENT_FAIL') {
        notifyWarning('Attention', WsError.RECAPTCHA_ASSESMENT_FAIL, 'Recharger', () => window.location.reload());
      } else {
        notifyError('Erreur', getErrorMessage(error));
      }
    })
    .finally(onFinally)
}

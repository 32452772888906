import {
    USER_EDENRED_BALANCE_FAIL,
    USER_EDENRED_BALANCE_SUCCESS,
    USER_EDENRED_REMOVE_FAIL,
    USER_EDENRED_REMOVE_SUCCESS,
    USER_EDENRED_TOKEN_REFRESH,
    USER_EDENRED_USERINFO_FAIL,
    USER_EDENRED_USERINFO_REQUEST,
    USER_EDENRED_USERINFO_SUCCESS
} from '../constants/userConstants';
import { getUserEdenredEmail, getUserEmail } from '../utils/StringUtils';

export const updateToken = (data) => async (dispatch, getState) => {
  dispatch({type: USER_EDENRED_TOKEN_REFRESH, payload: data});
  localStorage.setItem('user', JSON.stringify(getState().user));
};

export const resetEdenredError = () => {
    const newEdenred = JSON.parse(localStorage.getItem('user'));
    newEdenred.edenred.error = false;
    localStorage.setItem("user", JSON.stringify(newEdenred));
}
export const getEdenredUserinfo = () => async(dispatch, getState) => {
    dispatch({type: USER_EDENRED_USERINFO_REQUEST})

    try{
        var data = JSON.parse(localStorage.getItem('user'))
        data = data.edenred
        data.img = "https://firebasestorage.googleapis.com/v0/b/pikkopay.appspot.com/o/Webapp%2Fcart%2FEdenred-Logo.png?alt=media&token=48042cb0-3bea-4bb5-948d-4e629a1ae046"
        dispatch({type: USER_EDENRED_USERINFO_SUCCESS, payload: data});

    } catch(err){
        console.log(err)
        dispatch({type: USER_EDENRED_USERINFO_FAIL, payload: err.message})
    }
}


export const removeEdenred = (edenredInstance, refresh_token, user ) => async(dispatch, getState) => {
    const userId = user._id
    try {
        await edenredInstance.post("/edenred/delete", {
            refresh : refresh_token,
            userId: userId
        })
        dispatch({type: USER_EDENRED_REMOVE_SUCCESS})
        localStorage.setItem("user", JSON.stringify(getState().user));
    } catch(err) {
        localStorage.removeItem("Edenred");
        dispatch({type: USER_EDENRED_REMOVE_FAIL});
    }
}

export const updateEdenred = (data) => async(dispatch, getState) => {
    dispatch({type: USER_EDENRED_BALANCE_SUCCESS, payload: data})
    localStorage.setItem('user', JSON.stringify(getState().user));
}
export const clearEdenred = (error) => async(dispatch, getState) => {
    dispatch({type: USER_EDENRED_BALANCE_FAIL, payload: error.message});
    localStorage.setItem('user', JSON.stringify(getState().user));
}
export const getEdenredBalance = (axiosInstance, edenred, userId ) => {
    return axiosInstance.put("/edenred/balance", {
            username: edenred.username,
            accessToken: edenred.access_token,
            refreshToken: edenred.refresh,
            userId
        })
}

export const EdenredCapture = (order_amount, order, apiInstance) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return apiInstance.post('/edenred/capture', {
        order: order,
        amount: order_amount,
        edenred: user.edenred,
        userId: user._id
    });
}

export const EdenredConfirm = (authorized_amount, authorization_id, orderId, storeId, apiInstance) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return apiInstance.post('/edenred/payment', {
        authorized_amount: authorized_amount,
        authorization_id: authorization_id,
        storeId,
        orderId,
        userEmail: getUserEdenredEmail(user),
    })
}

export const EdenredCancel = async(authorized_amount, authorization_id ,apiInstance) => {
    const paymentCancel = await apiInstance.post('/edenred/cancel', {
        authorized_amount: authorized_amount,
        authorization_id: authorization_id
    })
    return paymentCancel
}

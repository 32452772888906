import React from "react";

const UserFidelityCardTile = ({ fidelityCardDetail }) => {
    return (
        <div className="fidelity_tile">
            <div className="header_fidelity">
                <div className="logo_fidelity">
                    <img src={fidelityCardDetail.companyImage} alt="" />
                </div>
                <div className="titulaire_box">
                    <div className="title_titulaire">
                        Titulaire
                    </div>
                    <div className="nom_titulaire">{
                      fidelityCardDetail.nom + " " + fidelityCardDetail.prenom
                    }</div>
                </div>
            </div>
            <div className="body_fidelity">
                <img src={fidelityCardDetail.cardImage} alt="" />
            </div>
            <div className="footer_fidelity">
                Compteur de visites : <span>{fidelityCardDetail.compteurVisite}</span>
            </div>
        </div>
    );
    }

export default UserFidelityCardTile;

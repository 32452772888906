import axios from 'axios';
import { useContext } from 'react';
import { authContext } from '../context/authProvider';

const Config = () => {
  const { auth, setAuth } = useContext(authContext);
  const Instance = axios.create({

    baseURL: process.env.REACT_APP_BACKEND_URL,

   // baseURL: "http://localhost:8080",
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return Instance;
};
export default Config;

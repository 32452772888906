import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CounterComponent = () => {
  // Utilisez useState pour gérer l'état local du compteur
  const [counter, setCounter] = useState(0);
  const user =useSelector(state=>state.user)
  // Utilisez useEffect pour récupérer la valeur du compteur depuis localStorage lors du chargement du composant
  useEffect(() => {
    const savedCounter = localStorage.getItem('counter');
    if (savedCounter) {
      setCounter(parseInt(savedCounter, 10));
    }
  }, []); // Le tableau vide [] en tant que deuxième argument signifie que ce code s'exécutera une seule fois, équivalent à componentDidMount dans les classes

  // Fonction pour incrémenter le compteur et mettre à jour localStorage
  const incrementCounter = () => {
    const updatedCounter = counter + 1;
    setCounter(updatedCounter);
    localStorage.setItem('counter', updatedCounter.toString());
  };

  return (
 
      <p>{user.count}</p>
  
  
  );
};

export default CounterComponent;

import React from "react";
import codeBarre from '../../styles/img/codeBarre.svg';
import arrow from '../../styles/img/arrow/arrow-.svg';

export const FidelityCard2 = ({storeLogo,onClick}) => { // TODO : EVAL (Mohamed a créé deux composants avec le même nom)

    return (

      <div className="fidelity_card"
           onClick={onClick}
      >
          <div className="header_fidelity_card">
              <img className="logo_fidelity_card" src={storeLogo} alt="logo" />
              <div className="black_bar_fidelity_card">
                  <div className="title_fidelity_card">
                      associer ma carte de fidélité
                  </div>
                  <div className="arrow_fidelity_card">
                      <img src={arrow} alt="" />
                  </div>
              </div>
          </div>
          <div className="footer_fidelity_card">
              <img src={codeBarre} alt="" />
          </div>
      </div>

    )
}

export default FidelityCard2;

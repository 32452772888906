import { configure } from "scandit-sdk";

export const loadScanner = async () => {
  const licenseKey = process.env.REACT_APP_SCANDIT_KEY;
  await configure(licenseKey, {
    engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/",
  })
    .then(() => {
      window.isScannerConfigured = true;
      console.log("configure scanner success");
    })
    .catch((error) => {
      window.isScannerConfigured = false;
      console.log("---> error configure scanner", error);
    });
};
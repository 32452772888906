import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import "./styles/index.scss";
import { AuthProvider } from "./context/authProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import * as SDCCore from "scandit-web-datacapture-core";
// import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { configure } from "scandit-sdk";
import { loadScanner } from "./utils/ScannerUtils";


window.isScanditConfigured = false;

// // Charger la bibliothèque Scandit au préalable
// const loadScanditLibrary = async () => {
//   try {
//     await SDCCore.configure({
//       licenseKey: process.env.REACT_APP_SCANDIT_KEY,
//       libraryLocation: new URL(
//         "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.25.0/build/engine/",
//         document.baseURI
//       ).toString(),
//       moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
//     });
//     window.isScanditConfigured = true;
//     console.log("Scandit library loaded successfully");
//   } catch (error) {
//     console.error("Error loading Scandit library:", error);
//   }
// };

// loadScanditLibrary();

window.isScannerConfigured = false;
await loadScanner();

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      {/*<GoogleReCaptchaProvider reCaptchaKey="6LfjOt0pAAAAAFcEvh1DMhOaK2d-2f5o-J3Wa_cc">*/}
      <App />
      {/*</GoogleReCaptchaProvider>*/}
    </Provider>
  </AuthProvider>,
  document.getElementById("root")
);

navigator?.serviceWorker
  ?.getRegistrations()
  .then((registrationsArray) => {
    if (registrationsArray && registrationsArray[0]?.update())
      registrationsArray[0].update();
  })
  .catch((e) => {
    console.log(e);
  });

reportWebVitals();

serviceWorkerRegistration?.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    }
  },
});

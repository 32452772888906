import Lottie from 'lottie-react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import eye from '../../../styles/lottie/eye.json'
import eyeOff from '../../../styles/lottie/eye-off.json'
import loadingLottie from '../../../styles/lottie/loading.json'
import cgu from '../../../pdf/CGU.pdf'
import confidentialite from '../../../pdf/politique.pdf'
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_FID_CARD } from '../../../constants/routes.jsx';
import { useNavigate } from 'react-router-dom';
import { handleLogin } from '../../../utils/LoginService';
import AccountCreation from './AccountCreation';
import FidelityCard2 from '../../../components/card/FidelityCard2';

const Email = ({user, cartItems, resetEmail, axiosInstance,state, setState,forgot,setForgot,connexion,inscription,fidCompany,setFidCompany,fidExist, setFidExist}, ref) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [verification, setVerification] = useState(false)

    const [pagetitle, setPagetitle] = useState('Connexion')
    const [pw1, setPw1] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [pwConnect, setPwConnect] = useState('')
    const [createaccountLoading, setCreateaccountLoading] = useState(false)
    const [fidLoading, setFidLoading] = useState(true)
    const { store } = useSelector(state => state.store);

    useEffect(() => {
        resetEmail && resetEmail.length > 0 && (setEmail(resetEmail))
        setFidLoading(true)
        axiosInstance.get(`company/${cartItems.storeId}/fid`)
          .then(({data}) => {
            setFidLoading(false)
            if(data) {setFidCompany(true); setFidExist(true)}

          })
          .catch((error) => {
            setFidExist(false)
            setFidLoading(false)
            setFidCompany(false)
          })

    }, [])

    const handleForgottenPasswordDisplay = () => {
        navigate('resetpassword')
      }

    useImperativeHandle(ref, () => ({
        connexion,
    }))

      const handleSignIn = async() => {
        const email = document.getElementById('emailSignIn').value
        const password = verification ? pwConnect : pw1

        await handleLogin(
          setCreateaccountLoading,
          email,
          password,
          user,
          cartItems,
          axiosInstance,
          dispatch,
          navigate
        );
      }

    const onblurScrolltoTop = () => {
        window.scrollTo(0, 0)
        if (document.getElementById('page_account')) {
          document.getElementById('page_account').scrollTop = 0;
        }
        console.log('blur');
      }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <>
        {fidLoading  && (
          <div className="w-full h-full  flex flex-col items-center justify-center ">
           <Lottie
              animationData={loadingLottie}
              loop={true}
              autoplay={true}
              style={{ width: '100px', height: '100px' }} // Adjust the width and height as needed
            />
          </div>
        )}
        {fidCompany ? <>
           <div className="w-full h-full  flex flex-col items-center justify-start px-4">
            <div className="fidelity-card-button-container">
              <FidelityCard2
              storeLogo={store.logo}
              onClick={() => navigate(LOGIN_FID_CARD)}
              />
            </div>
           </div>
        </> :!fidLoading && !fidCompany &&
          <>
          {state === 'signUp'  ? (
            <AccountCreation
              email={email}
              setEmail={setEmail}
              showPassword={showPassword}
              onblurScrolltoTop={onblurScrolltoTop}
              handleTogglePasswordVisibility={handleTogglePasswordVisibility}
              password1={pw1}
              setPassword1={setPw1}
              createAccountLoading={createaccountLoading}
              setCreateaccountLoading={setCreateaccountLoading}
              connexion={connexion}
              user={user}
            />
          ) : (
            <div className="w-full h-full createaccount ">
              <div className="groupinput w-full mb-[200px] flex flex-col">
                <div className="inputGroup w-full flex flex-col items-start">
                  <label className="text-2xl m-2 mb-4" htmlFor="emailSignIn">Email</label>
                  <input
                    className="user_mail_input w-full"
                    type="email"
                    id="emailSignIn"
                    placeholder="Entrez votre email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    onBlur={onblurScrolltoTop}
                    onFocus={onblurScrolltoTop}

                  />
                </div>
                <div className="inputGroup w-full flex flex-col items-start">
                  <label className="text-2xl m-2 mb-4" htmlFor="passwordSignIn">Mot de passe</label>
                  <input
                    onFocus={onblurScrolltoTop}
                    onBlur={onblurScrolltoTop}
                    type={showPassword ? 'text' : 'password'}
                    id="passwordSignIn"
                    placeholder="Mot de passe"
                    value={verification ? pwConnect : pw1}
                    onChange={(e) => verification ? setPwConnect(e.target.value) : setPw1(e.target.value) }
                  />
                  <button
                    className="absolute top-[48%] right-8 transform -translate-y-1/2"
                    onClick={handleTogglePasswordVisibility}
                  >
                    {showPassword ?
                      <Lottie
                        animationData={eye}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} // Adjust the width and height as needed
                      />
                      :
                      <Lottie
                        animationData={eyeOff}
                        loop={false}
                        autoplay={true}
                        style={{ width: '20px', height: '20px' }} // Adjust the width and height as needed
                      />
                    }
                  </button>
                  <button
                    className=" text-2xl geomanistBold border-b-2 border-green-500 hover:border-green-700 font-black m-2 mt-4 focus:outline-none mb-4"
                    onClick={() => handleForgottenPasswordDisplay() }
                  >
                    Mot de passe oublié ?
                  </button>
                </div>
                {
                  !createaccountLoading?
                    <button className="payer w-full p-6 my-6 text-3xl geomanistBold" onClick={handleSignIn}>Me connecter </button>:
                    <div className="w-full flex justify-center items-center ">  <Lottie
                      animationData={loadingLottie}
                      loop={true}
                      autoplay={true}
                      style={{ width: '100px', height: '100px' }} // Adjust the width and height as needed
                    /></div>
                }

                <div style={{fontFamily: "Open Sans", fontWeight: 400}} className="top-12 text-center relative">
                  <span>En créant un compte j'accepte</span>
                  <a href={cgu} target="_blank"> Conditions Générales d'Utilisation </a> et la <a href={confidentialite} target="_blank">politique de confidentialité</a> de PikkoPay
                </div>
              </div>

            </div>
          )}
          </>
        }


        </>
    );
};

export default forwardRef(Email);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createCart } from "../../actions/cartActions";
import { SCAN, STORE_LIST } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInterceptors from "../../axios/useAxios";
import ScanButton from "../../components/button/ScanButton";
import penEdit from "../../styles/img/pen-edit.png";
import { isAllowToChangeStore } from "../../utils/Validator";
import { setScanCamera, setScanSettings } from "../../actions/scanActions";

const ScanFooter = ({ originPath }) => {
  const { store } = useSelector((state) => state.store);
  const { cartItems, success, loading } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInterceptors();
  const navigate = useNavigate();

  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (success && !cartItems.isPaid && reset) {
      navigate(SCAN);
    }
  }, [success]);

  useEffect(() => {
    if (success && !cartItems.isPaid && reset) {
      navigate(SCAN);
    }
  }, [success]);

  const editSelectedStore = () => {
    navigate(STORE_LIST, { state: { prevPage: originPath } });
  };

  const deployScan = () => {
    if (cartItems) {
      if (cartItems.isPaid) {
        const orderCreate = { storeId: store.id, clientId: "" };
        try {
          dispatch(
            createCart(orderCreate, axiosInstance, () => navigate(SCAN))
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        // if (
        //   scannerSettings.reassignScanner === "initial" ||
        //   scannerSettings.reassignScanner === undefined
        // ) {
        //   dispatch(setScanSettings(false));
        // } else {
        // }
        dispatch(setScanSettings(true));
        // dispatch(setScanCamera(true));
        navigate(SCAN);
      }
    } else {
      alert("Il faut d'abord choisir un magasin");
      navigate(STORE_LIST, { state: { prevPage: originPath } });
    }
  };

  return (
    <div className="scan-footer">
      {store && (
        <div className="footer-item">
          <div className="store-info">
            <img src={store.logo} alt="logo" className="store-img" />
            <div className="store-detail">
              <div className="store-name">{store.name}</div>
              <div className="store-city">{store.city}</div>
            </div>
          </div>
          {isAllowToChangeStore(store) && (
            <div className="edit-img-container" onClick={editSelectedStore}>
              <img src={penEdit} alt="logo" className="edit-img" />
            </div>
          )}
        </div>
      )}
      <div className="footer-item">
        <ScanButton
          onClick={deployScan}
          label="Scanner"
          store={store}
          editSelectedStore={editSelectedStore}
        />
      </div>
    </div>
  );
};

export default ScanFooter;

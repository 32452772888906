import React from 'react';

const FidelityCardTile = ({ onClick, img }) => {
  return (
    <div className="fidelity-card-tile" onClick={onClick}>
      <img
        className="fidelity-card-tile-img"
        src={img}
        // style={{ background: brand.color }}
      />
    </div>
  );
};

export default FidelityCardTile;

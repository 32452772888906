import React from 'react';
import BrandTile from './BrandTile';
import { useNavigate } from 'react-router';
import { BRAND } from '../../constants/routes';
import coming_soon from '../../styles/img/coming-soon.svg';

const BrandList = ({ brands, showTitle, showPromoText, originPath, disableClick }) => {
  const navigate = useNavigate();

  const redirectToBrandPage = (brandId) => {
    navigate(`${BRAND}/${brandId}`, { state: { prevPage: originPath }});
  };

  const onClick = (brandId) => {
    if (disableClick) {
      return;
    }
    redirectToBrandPage(brandId);
  };

  return (
    <div className="brand-list">
      {showTitle && (<div className="brand-title">
        Gagnes des Points
      </div>)}
      <div className="brand-container">
        {brands.map((brand) => (
          <BrandTile
            key={brand._id}
            onClick={() => onClick(brand._id)}
            brand={brand}
            showDetail
            showPromoText={showPromoText}
          />
        ))}
        <div className="brand-tile" >
        <div className="brand-logo-container" style={{background:'#BFBFBF'}}>
          <img
            
            src={coming_soon}
            alt={"coming soon"}
            style={{objectFit: 'contain',width:'90%'}}
          />
        </div>
        </div>
      </div>
    </div>
  );
};

export default BrandList;

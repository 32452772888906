import { SCAN_DISPLAY, SCAN_REASSIGN } from '../constants/scanConstants';

export const scanReducers = (
  state = { reassignScanner: 'initial' },
  action
) => {
  switch (action.type) {
    case SCAN_DISPLAY:
      return { ...state, display: action.payload };

    case SCAN_REASSIGN: {
      return { ...state, reassignScanner: action.payload };
    }

    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import useAxiosInterceptors from '../../../axios/useAxios';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLoader from '../../../components/loader/PageLoader';
import BackButton from '../../../components/button/BackButton';
import UserFidelityCardTile from '../../../components/card/UserFidelityCardTile';
import { USER_FID_CARD } from '../../../constants/routes';
import ScanFooter from '../ScanFooter';
import { notifySuccess } from '../../../utils/Notify';
import { ACCOUNT } from '../../../constants/routes';
import { useSelector } from 'react-redux';
const UserFidelityCardDetail = () => {
  const axiosInstance = useAxiosInterceptors();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fidelityCardDetail, setFidelityCardDetail] = useState(undefined);
  const { state } = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.fidCard) {
      return;
    }
    if (state?.fromLogin) {
      notifySuccess('Carte de fidélité ajoutée avec succès');
    }
    setIsLoading(true);
    axiosInstance.put('/apiws/fidelity-card/detail', {
      fidelityCardPhysicalCardNum: user?.fidCard[0]?.fidelityInfo.ficheClient.numeroCarte,
      fidelityCardBddId: user?.fidCard[0]?.fidelityCardBddId
    })
      .then((response) => {
        setFidelityCardDetail(response.data);
      })
      .catch((error) => {
        console.error({ error }); // TODO : handle error
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onBack = () => {
    if (state?.fromLogin) {
      navigate(ACCOUNT);
    } else {
      navigate(USER_FID_CARD);
    }
  };

  return (
    <div id="user-fidelity-card-detail">
      {isLoading
        ? (<PageLoader />)
        : (
          <>
            <div className="user-fidelity-card-detail-content">
             <BackButton onClick={onBack}  />
              {fidelityCardDetail && (
                <>
                  <UserFidelityCardTile fidelityCardDetail={fidelityCardDetail} />
                  <div className="avantage-fidelity ">
                    <div className="header-avantage-fidelity">
                      Fidélité {fidelityCardDetail.name}
                    </div>
                    <div className="detail-avantage-fidelity">
                      {fidelityCardDetail.description}
                    </div>
                  </div>
                  <div className="info-fidelity">
                    <div className="header-info-fidelity">
                      Supermarché {fidelityCardDetail.name}
                    </div>
                    <div className="details-info-fidelity">
                      {fidelityCardDetail.companyDescription}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )
      }
      <ScanFooter originPath={USER_FID_CARD} />
    </div>
  );
};

export default UserFidelityCardDetail;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const PromoCarte = ()=>{
 
  const user = useSelector(state=> state.user)
  const [visibility, setVisibility] = useState(true);
  const navigate = useNavigate()
  var non = document.querySelector(".non")
 
 const onclick_non = ()=>{
       setVisibility(false)
       localStorage.setItem('promocarte', 'non');
      }

 const onclick_oui = ()=>{
        setVisibility(false)
        localStorage.setItem('promocarte', 'oui');
        navigate('/promo')
      }

  
return(
  localStorage.getItem('promocarte')===undefined?<>
   {user.count===0 || user.count === undefined? <> 
       <div className={visibility?"contenaire_promo_carte":"hidden"}>
              <div className="carte_promo">
                <span className="price_fond">1€</span>
                <h2>Profite de 1€ pour ton 1er achat</h2>
                <p>Puis <span>2€</span> pour le 2e et <span>5€</span> pour le 3e</p>
                <div className="btn_promo_carte">
                  <button className="non" onClick={onclick_non}>Non merci</button><button className="oui" onClick={onclick_oui}>Active la promo</button>
                </div>
              </div>
        </div>
    </>: user.usedPromo && user.usedPromo.includes('promo1e')?
      <><div className={visibility?"contenaire_promo_carte":"hidden"}>
      <div className="carte_promo">
        <span className="price_fond">2€</span>
        <h2>Profite de 2€ pour votre 2e achat</h2>
        <p>Puis <span>5€</span> pour le 3e</p>
        <div className="btn_promo_carte">
          <button className="non" onClick={onclick_non}>Non merci</button><button className="oui" onClick={onclick_oui}>Active la promo</button>
        </div>
      </div>
</div></>
    :user.usedPromo && user.usedPromo.includes('promo2e') ?
    <><div className={visibility?"contenaire_promo_carte":"hidden"}>
    <div className="carte_promo">
      <span className="price_fond">5€</span>
      <h2>Profite de 5€ pour ton 5e achat</h2>
     
      <div className="btn_promo_carte">
        <button className="non" onClick={onclick_non}>Non merci</button><button className="oui" onClick={onclick_oui}>Active la promo</button>
      </div>
    </div>
</div></>:<></>
  }</>:<></>

)
}

export default PromoCarte;
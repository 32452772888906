import {
  SCAN_DISPLAY,
  SCAN_REASSIGN,
  SCAN_RESUME_CAMERA,
} from "../constants/scanConstants";

export const scanDisplay = (display) => async (dispatch, getState) => {
  dispatch({ type: SCAN_DISPLAY, payload: display });
  localStorage.setItem("scanner", JSON.stringify(getState().scanner));
};

export const setScanSettings = (payload) => async (dispatch) => {
  dispatch({ type: SCAN_REASSIGN, payload });

  let obj = {
    reassignScanner: payload,
    ...JSON.parse(localStorage.getItem("scanner")),
  };

  localStorage.setItem("scanner", JSON.stringify(obj));
};

export const setScanCamera = (payload) => async (dispatch) => {
  dispatch({ type: SCAN_RESUME_CAMERA, payload });

  let obj = {
    resumeCamera: payload,
    ...JSON.parse(localStorage.getItem("scanner")),
  };

  localStorage.setItem("scanner", JSON.stringify(obj));
};
